import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = (props) => {
    const chart = {
        series: [{
            name: props.currentYear - 1,
            data: props.lastYearData
          }, {
            name: props.currentYear,
            data: props.currentYearData
          }],
          options: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                show: false
              },
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
              },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
                title: {
                    text : 'Month',
                },
              categories: ['1','2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            },
            yaxis: {
              title: {
                text: 'KWH'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " KWh"
                }
              }
            }
          },
    }

    return(
        <Chart
        type="bar"
        {...chart}
        />
    )
    
}

export default BarChart;