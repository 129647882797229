import React from 'react';
import app from '../firebase/firebaseConfig';
import { Button, Card, Checkbox, Divider, FormControlLabel, TextField, Typography} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import secondaryApp from '../firebase/secondaryApp'
// import { Divide } from 'react-feather';


class Admin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userData : null,
            meterData : null,
            breakerControl : '',
            nFeeders : null,
            nDG : null,
            email : '',
            name : '',
            password : '',
            adminUid : null,
            next : false,
            clientNames : [],
            clientName : '',
            breakerPass : '',
            show : false
        }

    }

    componentDidMount = () => {
        app.auth().onAuthStateChanged((user) =>  {
            if (user) {
                this.setState({adminUid : user.uid})
                app.database().ref(`Users/${user.uid}/users`).on("value", (snapShot) => {
                    if (snapShot.exists) {
                        console.log(snapShot.val())
                        this.setState({userData : snapShot.val()})
                    }
                    
                })
            }
        })
    }

    handleRemoveUser = () => {

    }

    handleCreateUser = () => {
        secondaryApp.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((user) => {
            if (user) {
                let newUserId = user.user.uid
                app.database().ref(`Users/${this.state.adminUid}/users`).update({[newUserId] : {"name" : this.state.name, "email" : this.state.email}}).then(() => {
                    this.setState({name : null, email: null, password: null})
                    let data = this.state.meterData
                    if (this.state.breakerPass !== '') {
                        data['breakerPass'] = this.state.breakerPass
                        data['breakerControl'] = this.state.breakerControl
                    }
                    console.log(data)
                    // if (!this.state.checked) {
                    //     delete data['breakerControl']
                    //     delete data['breakerPass']
                    // }
                    app.database().ref(`Users/${newUserId}`).update(data)
                }).then(() => {
                    secondaryApp.auth().signOut()
                    this.setState({meterData: null, breakerControl: '', breakerPass : '', nDG : null, nFeeders : null, next : false, show: false})
                })
                
            }
        })
    }
    handleNext = () => {
        if (this.state.email !== '' && this.state.password !== '') {
            app.firestore().collection("Client").doc("ClientNames").get().then((doc) => {
                if (doc.exists) {
                    let data = doc.data()['Names']
                    this.setState({clientNames : data})
                    this.setState({next : true})
                }
            })
        }
    }

    handleClientChange = (e, newValue) => {
        let clientName = newValue
        let meterRef = app.firestore().collection("Meters")
        if (clientName !== undefined) {
            let query = meterRef.where("clientName", "==", clientName)
            let feederMeter = {}; let incomerMeter = {}; let dgMeter = {}
            let feederKeys = []; let incomerKeys = []; let dgKeys = []
            query.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        let meterID = doc.id
                        let meterDetails = doc.data()
                        if (meterDetails["meterType"] === "feeder") {
                            feederMeter[meterID] = meterDetails["meterName"]
                            feederKeys.push(meterID)
                        } else if (meterDetails["meterType"] === "Incomer") {
                            incomerMeter[meterID] = meterDetails["meterName"]
                            incomerKeys.push(meterID)
                        }else if (meterDetails["meterType"] = 'dg') {
                            dgMeter[meterID] = meterDetails["meterName"]
                            dgKeys.push(meterID)
                        }
                    }
                })
                let meters = {
                    dgMeters : dgMeter,
                    feederMeters : feederMeter,
                    incomerMeter : incomerMeter
                }
                this.setState({meterData : meters, nFeeders : feederKeys.length, nDG : dgKeys.length})
                this.setState({show : true})
            })
        } else {
            this.setState({show : false})
        }        
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, gap:40}}>
                <div style={{display:'flex', flex:1, display:'flex'}}  >
                    <Card style={{display:'flex', flex:1, flexDirection:'row', padding:20}} elevation={4}>
                        <div style={{flex:.4, marginRight:20}}>
                            <Typography>Registered Clients</Typography>
                            {this.state.userData ?
                            <div style={{display:'flex', gap:10, flexDirection:'column'}}>{Object.keys(this.state.userData).map((key, index) => 
                                <Card variant={'outlined'} style={{display:'flex', justifyContent:'space-between', padding:10}} key={key}>
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        {this.state.userData[key]['name']}
                                        <Typography variant='caption'> {this.state.userData[key]['email']} </Typography>
                                    </div>
                                    {/* <Button variant='outlined' onClick={this.handleRemoveUser} title='Remove'>Remove</Button> */}
                                    
                                </Card>
                            )} </div> : <Typography variant='caption'>No Client Registered.</Typography>}
                        </div>
                        <Divider orientation="vertical" style={{height: '50%', alignSelf:'center'}} />
                        {!this.state.next  ? 
                        <div style={{display:'flex', flex:.6, justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                            <Typography>ADD Client</Typography>
                            <TextField variant='outlined' autoComplete={"fullname"}  label='Full name' style={{marginTop:8, marginBottom:8, width:230}} onChange={(event) => this.setState({name : event.target.value})} value={this.state.name} />
                            <TextField variant='outlined' autoComplete={"username"}  label='Email Address' style={{marginBottom:8, width:230}} onChange={(event) => this.setState({email : event.target.value})} value={this.state.email} />
                            <TextField variant='outlined' autoComplete={"password"}  label='Password' helperText="*Note Pass word and share with client only" style={{marginBottom:8, width:230}} onChange={(event) => this.setState({password : event.target.value})} value={this.state.password} />
                            <Button variant='text' color='primary' style={{borderRadius:50, width:230, padding:10, marginBottom:8, background:'transparent linear-gradient(180deg, #FFFFFF 8%, #F1F2F8 23%, #CDD1E5 66%, #C0C5DF 90%) 0% 0% no-repeat padding-box', boxShadow:'0px 6px 3px #00000059', border:'0.6265419721603394px solid #FFFFFF', color:'#3B3D55'}} onClick={this.handleNext}>Next</Button>
                        </div> : 
                        <div style={{display:'flex', flex:.6, flexDirection:'column', padding:20, width:'100%', alignItems:'center'}}>
                            <Autocomplete
                                id="combo-box"
                                options={this.state.clientNames}
                                // value = {this.state.clientName}
                                onChange={this.handleClientChange}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Select Client Name" variant="outlined" />}
                                />
                            {this.state.show ?
                            <div style={{display:'flex', gap:20, flexDirection:'row', width:'100%', paddingLeft:20, marginTop:20}}>
                                <Card variant='elevation' elevation={4} style={{display:'flex', flex:1,  alignItems:'center', justifyContent:'space-between', padding:"5px 5px 5px 20px", borderRadius:14}}>
                                    <Typography style={{fontSize:12, display:'flex', flex:.8}}>NO OF FEEDERS</Typography>
                                    <Typography style={{color:'white', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10}}>{this.state.nFeeders ? this.state.nFeeders : "NAN"}</Typography>
                                </Card> 
                                <Card variant='elevation' elevation={4} style={{display:'flex', flex:1, alignItems:'center', justifyContent:'space-between', padding:"5px 5px 5px 20px", borderRadius:14}}>
                                    <Typography style={{fontSize:12, display:'flex', flex:.8}}>NO OF DG'S</Typography>
                                    <Typography style={{color:'white', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10}}>{this.state.nDG ? this.state.nDG : 0}</Typography>
                                </Card> 
                            </div> : null}
                            {this.state.meterData ? 
                            <Card variant='outlined' style={{display:'flex', flex:1, marginTop:20, width:'inherit', gap:20}}>
                                <Card variant='outlined' style={{flex:1,}}>
                                <Typography variant='caption'>Feeder Meters</Typography>
                               {Object.keys(this.state.meterData.feederMeters).map((key, index) => 
                                    <Card variant='outlined'>
                                        <div style={{flex:1, display:'flex', justifyContent:'space-between', marginLeft:5, marginRight:5}}>
                                            <Typography variant='caption'>Meter ID</Typography>
                                            <Typography variant='button'>{key}</Typography>
                                        </div>
                                        <div style={{flex:1, display:'flex', justifyContent:'space-between', marginLeft:5, marginRight:5}}>
                                            <Typography variant='caption'>Meter Name</Typography>
                                            <Typography variant='button'>{this.state.meterData.feederMeters[key]}</Typography>
                                        </div>
                                   </Card>
                               )}
                               </Card>
                               <Card variant='outlined' style={{flex:1}}>
                                <Typography variant='caption'>Incomer Meters</Typography>
                               {Object.keys(this.state.meterData.incomerMeter).map((key, index) => 
                                    <Card variant='outlined'>
                                        <div style={{flex:1, display:'flex', justifyContent:'space-between', marginLeft:5, marginRight:5}}>
                                            <Typography variant='caption'>Meter ID</Typography>
                                            <Typography variant='button'>{key}</Typography>
                                        </div>
                                        <div style={{flex:1, display:'flex', justifyContent:'space-between', marginLeft:5, marginRight:5}}>
                                            <Typography variant='caption'>Meter Name</Typography>
                                            <Typography variant='button'>{this.state.meterData.incomerMeter[key]}</Typography>
                                        </div>
                                   </Card>
                               )}
                               </Card>
                               <Card variant='outlined' style={{flex:1}}>
                                <Typography variant='caption'>DG Meters</Typography>
                               {Object.keys(this.state.meterData.dgMeters).map((key, index) => 
                                    <Card variant='outlined'>
                                        <div style={{flex:1, display:'flex', justifyContent:'space-between', marginLeft:5, marginRight:5}}>
                                            <Typography variant='caption'>Meter ID</Typography>
                                            <Typography variant='button'>{key}</Typography>
                                        </div>
                                        <div style={{flex:1, display:'flex', justifyContent:'space-between', marginLeft:5, marginRight:5}}>
                                            <Typography variant='caption'>Meter Name</Typography>
                                            <Typography variant='button'>{this.state.meterData.dgMeters[key]}</Typography>
                                        </div>
                                   </Card>
                               )}
                               </Card>

                            </Card> : null}

                            {this.state.show ? 
                            <div style={{display:'flex', width:'inherit', gap:20, marginTop:20}}>
                                <Card variant='outlined' elevation={4} style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:"5px 5px 5px 20px", borderRadius:14}}>
                                    <Typography style={{fontSize:12, display:'flex', flex:.8}}>Breaker ID</Typography>
                                    <TextField variant='standard' autoComplete={"new-username"}  label='' style={{marginBottom:8, width:230}} onChange={(event) => this.setState({breakerControl : event.target.value})} value={this.state.breakerControl} />
                                </Card> 
                                <Card variant='outlined' elevation={4} style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:"5px 5px 5px 20px", borderRadius:14}}>
                                    <Typography style={{fontSize:12, display:'flex', flex:.8}}>Set Breaker Control Pass</Typography>
                                    <TextField variant='standard' autoComplete={"new-username"}  label='' style={{marginBottom:8, width:230}} onChange={(event) => this.setState({breakerPass : event.target.value})} value={this.state.breakerPass} />
                                </Card> 
                                <Button variant='text' color='primary' style={{borderRadius:50, width:230, padding:10, marginBottom:8, background:'transparent linear-gradient(180deg, #FFFFFF 8%, #F1F2F8 23%, #CDD1E5 66%, #C0C5DF 90%) 0% 0% no-repeat padding-box', boxShadow:'0px 6px 3px #00000059', border:'0.6265419721603394px solid #FFFFFF', color:'#3B3D55'}} onClick={this.handleCreateUser}>Create User</Button>
                            </div>
                            : null}       
                        </div> }
                    </Card>
                </div>
            </div>
        )
    }
}

export default Admin;