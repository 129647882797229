import React from 'react';
import { Link, NavLink, HashRouter, Route } from "react-router-dom";
import {withRouter, } from 'react-router'; 
import {AppBar, Hidden, IconButton, SvgIcon, Toolbar, Box, Typography, List, ListItem, Drawer, Button} from '@material-ui/core'
import { Menu as MenuIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logo from './svg/logo.svg';
import LandingHome from './LandingPage/LandingHome'
import AboutUs from './LandingPage/AboutUs'
import Dashboard from './dashboard/Dashboard'
import Incomer from './dashboard/dashboardPages/Incomer';
import FeederMonitoring from './dashboard/dashboardPages/FeederMonitoring';
import Feeder from './dashboard/dashboardPages/Feeder';
import Trends from './dashboard/dashboardPages/Trends';
import MeterSettings from './dashboard/dashboardPages/MeterSettings';
import DgData from './dashboard/dashboardPages/DgData';
import BreakerControl from './dashboard/dashboardPages/BreakerControl';
import ConsumptionTrends from './dashboard/dashboardPages/ConsumptionTrends';
import app from './firebase/firebaseConfig';
import CircularProgress from '@material-ui/core/CircularProgress';
import Admin from './LandingPage/Admin';

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openNav : false,
            authenticated : false,
            userType : null,
            load : false
        }
    }
    componentDidMount() {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({authenticated : true})
                app.database().ref(`Users/${user.uid}/userType`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        this.setState({userType : snapShot.val()})
                        this.setState({load : true})
                    } else {
                        this.setState({load : true})
                    }
                })
            } else {
                this.setState({authenticated : false})
                this.setState({load : true})
            }
        })
    }

    handleLogout = () => {
        app.auth().signOut();
        this.setState({authenticated:false})
        this.setState({userType : null})
    }

    render() {
        const MobileContent = (
            <Box
            height="100%"
            display="flex"
            flexDirection="column"
            >
            <PerfectScrollbar options={{ suppressScrollX: true }} style={{height:'100%'}}>
                <Box p={2}>
                <List>
                <NavLink to='/' exact style={{textDecoration:'none', color:'#adb0bb',}} activeStyle={{
                            fontWeight: 500,
                            color: "#5850EC"
                        }}>
                        <ListItem button key={1}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Home</Typography>
                        </ListItem>
                    </NavLink>
                    {/* <NavLink style={{textDecoration:'none', color:'#adb0bb', }} activeStyle={{
                                fontWeight: 500,
                                color: "#5850EC"
                            }}  to='/aboutUs'>
                        <ListItem button key={2}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>About Us</Typography>
                        </ListItem>
                    </NavLink>
                    <NavLink style={{textDecoration:'none', color:'#adb0bb', }} activeStyle={{
                                fontWeight: 500,
                                color: "#5850EC"
                            }}  to='/gallery'>
                        <ListItem button key={2}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Gallery</Typography>
                        </ListItem>
                    </NavLink>
                    <NavLink style={{textDecoration:'none', color:'#adb0bb', }} activeStyle={{
                                fontWeight: 500,
                                color: "#5850EC"
                            }}  to='/newsFeed'>
                        <ListItem button key={2}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>News Feed</Typography>
                        </ListItem>
                    </NavLink>
                    <NavLink style={{textDecoration:'none', color:'#adb0bb', }} activeStyle={{
                                fontWeight: 500,
                                color: "#5850EC"
                            }}  to='/contactUs'>
                        <ListItem button key={2}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Contact Us</Typography>
                        </ListItem>
                    </NavLink> */}
                    {this.state.userType ? 
                        <NavLink style={{textDecoration:'none', color:'#adb0bb', }} activeStyle={{
                                fontWeight: 500,
                                color: "#5850EC"
                            }}  to='/admin'>
                            <ListItem button key={2}>
                                <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Admin</Typography>
                            </ListItem>
                        </NavLink> : null}
                </List>                        
                </Box>
                </PerfectScrollbar>
                </Box>
        )
        const DesktopContent = (
            <Box
            height="100%"
            display="flex"
            flexDirection="row"
            >
                <Box p={2}>
                <List style={{display:'flex', gap:40}}>
                    <NavLink to='/' exact style={{textDecoration:'none', color:'white', width:120, alignItems:'center', borderRadius:20, backgroundColor:'black'}} activeStyle={{
                            fontWeight: 500,
                            color: 'black',
                            backgroundColor:'white',
                            // borderColor:'black',
                            border:'1px solid black' 
                        }}>
                        <ListItem button key={1} style={{justifyContent:'center'}}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Home</Typography>
                        </ListItem>
                    </NavLink>
                    {/* <NavLink style={{textDecoration:'none', color:'white', width:120, borderRadius:20, backgroundColor:'black'}} activeStyle={{
                                fontWeight: 500,
                                color: 'black',
                                backgroundColor:'white',
                                // borderColor:'black',
                                border:'1px solid black' 
                            }}  to='/aboutUs'>
                        <ListItem button key={2} style={{justifyContent:'center'}}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>About Us</Typography>
                        </ListItem>
                    </NavLink>
                    <NavLink style={{textDecoration:'none', color:'white', width:120, borderRadius:20, backgroundColor:'black'}} activeStyle={{
                                fontWeight: 500,
                                color: 'black',
                                backgroundColor:'white',
                                // borderColor:'black',
                                border:'1px solid black' 
                            }}  to='/gallery'>
                        <ListItem button key={3} style={{justifyContent:'center'}}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Gallery</Typography>
                        </ListItem>
                    </NavLink>
                    <NavLink style={{textDecoration:'none', color:'white', width:120, borderRadius:20, backgroundColor:'black'}} activeStyle={{
                                fontWeight: 500,
                                color: 'black',
                                backgroundColor:'white',
                                // borderColor:'black',
                                border:'1px solid black' 
                            }}  to='/newsFeed'>
                        <ListItem button key={4} style={{justifyContent:'center'}}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>News Feed</Typography>
                        </ListItem>
                    </NavLink>
                    <NavLink style={{textDecoration:'none', color:'white', width:120, borderRadius:20, backgroundColor:'black'}} activeStyle={{
                                fontWeight: 500,
                                color: 'black',
                                backgroundColor:'white',
                                // borderColor:'black',
                                border:'1px solid black' 
                            }}  to='/contactUs'>
                        <ListItem button key={5} style={{justifyContent:'center'}}>
                            <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Contact Us</Typography>
                        </ListItem>
                    </NavLink> */}
                    {this.state.userType ? 
                        <NavLink style={{textDecoration:'none', color:'white', width:120, borderRadius:20, backgroundColor:'black'}} activeStyle={{
                                fontWeight: 500,
                                color: 'black',
                                backgroundColor:'white',
                                // borderColor:'black',
                                border:'1px solid black' 
                            }}  to='/admin'>
                            <ListItem button key={5} style={{justifyContent:'center'}}>
                                <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>Admin</Typography>
                            </ListItem>
                        </NavLink>
                         : null}
                </List>                        
                </Box>
            </Box>
        )
        if (this.state.load === false) {
            let screenHeight = window.innerHeight
            return (
                <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', height:screenHeight}}>
                    <CircularProgress />
                </div>
            )
        }else {
            let screenHeight = window.innerHeight
            return( 
                <HashRouter>
                    <div style={{display:'flex', flex:1, height:screenHeight, flexDirection:'column'}}>
                        
                    {!this.props.location.hash.split('/')[2] ? <AppBar color='transparent' variant='outlined' position='sticky' style={this.props.location.hash.split('/')[1] !== 'dashboard' ? {display:"flex", flexDirection:"row", borderColor:'white', flex:1, maxHeight:150, zIndex:1300 }: {display:"flex", flexDirection:"row", borderColor:'white', flex:1, maxHeight:100, zIndex:1300 }}>
                        <div style={this.props.location.hash.split('/')[1] !== 'dashboard' ? {margin:"10px 20px 5px 20px", flex:1, display:'flex', alignItems:'center'} :  {margin:"10px 20px 5px 20px", flex:1, display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <Link to='/'><img alt='logo' src={logo} width={this.props.location.hash.split('/')[1] !== 'dashboard' ? 120 : 80}></img></Link>
                        </div>
                        {this.props.location.hash.split('/')[1] !== 'dashboard' ?
                        <Toolbar style={{display:'flex'}}>
                            <div style={{margin:"6px 20px 7px 20px", display:'flex', flexDirection:'row-reverse', alignItems:'center', justifyContent:'center',gap:5}}>
                                <div>
                                    {/* Mobile Menu */}
                                    <Hidden lgUp>
                                        <Drawer
                                        anchor="right"
                                        style={{ paper: {width: 256}}}
                                        onClose={() => this.setState({openNav : false})}
                                        open={this.state.openNav}
                                        variant="temporary"
                                        >
                                        {MobileContent}
                                        </Drawer>
                                    </Hidden>
                                    <Hidden mdDown>
                                        {DesktopContent} 
                                    </Hidden>
                                </div>
                            </div>
                        </Toolbar>
                        : null }
                        {this.props.location.hash.split('/')[1] !== 'dashboard' ?
                        <Hidden lgUp>
                            <IconButton
                                style={{padding:24}}
                                color="inherit"
                                onClick={() => this.setState({openNav : true})}
                            >
                                <SvgIcon fontSize="small">
                                <MenuIcon />
                                </SvgIcon>
                            </IconButton>
                        </Hidden> : null }
                        
                    </AppBar> : null}
                    <div style={{display:'flex', flex:1, height:"100%"}}>
                        <Box style={{margin:20, display:'flex', flex:1}}>
                            <Route exact path='/' render={
                                props => <LandingHome {...props} logout={this.handleLogout}/>
                            }/>
                            <Route exact path='/aboutUs' component={AboutUs} />
                            <Route exact path='/admin' component={Admin} />
                            <Route exact path='/dashboard' component={Dashboard} />
                            <Route exact path='/dashboard/incomerDetailed' component={Incomer} />
                            <Route exact path='/dashboard/feederMonitor' component={FeederMonitoring} />
                            <Route exact path='/dashboard/feederMonitor/detailed' component={Feeder} />
                            <Route exact path='/dashboard/trends' component={Trends} />
                            <Route exact path='/dashboard/dgData' component={DgData} /> 
                            <Route exact path='/dashboard/meterSettings' component={MeterSettings} />
                            <Route exact path='/dashboard/breakerControls' component={BreakerControl} />
                            <Route exact path='/dashboard/consumptionTrends' component={ConsumptionTrends} />
                        </Box>
                    </div>
                    {this.props.location.hash.split('/')[1] === 'dashboard' ? <div style={{display:'flex', flex:1, maxHeight:40, padding:10, justifyContent:'flex-end', marginRight:40}}>
                        <Button title='Logout' onClick={this.handleLogout}  variant="outlined">Logout</Button>
                    </div> : null}
                    </div>
                </HashRouter>
                
            )
        }
    }
}

export default withRouter(Home)