import React from 'react'
import {RadialGauge} from 'canvas-gauges'

class ReactRadialGaugeSmall extends React.Component {
  componentDidMount () {
    
    const options = Object.assign({}, this.props, {
    renderTo: this.el,
    width: (window.innerWidth)*0.12,
    height: (window.innerWidth)*0.12,
    colorMajorTicks: "white",
    colorMinorTicks: "white",
    colorNumbers: "white",
    colorTitle: 'white',
    // units: this.props.data ? this.props.data.units : "KW",
    minValue: 100,
    maxValue: 900,
    majorTicks: this.props.majorTicks,
    highlights : [],
    valueBox : false,
    minorTicks: 5,
    strokeTicks: false,
    colorPlate: "rgba(255,255,255, 0)",
    borderShadowWidth: 2,
    value: this.props.value ? this.props.value : 0,
    borders: false,
    needleType: "arrow",
    needleEnd:60,
    needleStart: 0,
    needleWidth: 2,
    colorNeedle: "white",
    needleShadow: false,
    startAngle: 90,
    ticksAngle: 180,
    borderOuterWidth:10,
    colorNeedleCircleOuter: 'white',
    colorNeedleCircleOuterEnd: 'white',
    colorNeedleCircleInner: 'white',
    colorNeedleCircleInnerEnd: 'white',
    needleCircleSize: 7,
    // colorValueBoxRect:'black',
    colorNeedleShadowUp : '',
    highlightsLineCap:'square',
    needleCircleOuter: true,
    needleCircleInner: true,
    animationDuration: 10,
    animationRule: "linear"
    })
    this.gauge = new RadialGauge(options).draw()
  }
  
  componentWillReceiveProps (nextProps) {
    this.gauge.value = nextProps.value
    this.gauge.range = nextProps.range
    this.gauge.update(nextProps)
  }

  render () {
    return (
            <canvas ref={(canvas) => {
                this.el = canvas
            }} >
            </canvas>
    )
  }
}

export default ReactRadialGaugeSmall