import React from 'react';
import { Link } from 'react-router-dom';
import {Typography, Button, Card,  FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import LiveTrends from '../../svg/icons/liveTrends.svg';
import BreakerControl from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import ReactRadialGauge from '../widgets/RadialGauge';
import PFGauge from '../widgets/PFGauge';
import FrequencyGauge from '../widgets/FrequencyGauge'
import ReactRadialGaugeSmall from '../widgets/RadialGaugeS';
import rainbowRing from '../../svg/rainbowRing.svg';
import ring from '../../svg/ring.svg'
import yellowRing from '../../svg/yellowRing.svg'
import BlueRing from '../../svg/BlueRing.svg'
import app from '../../firebase/firebaseConfig';
import QuatRadial from '../widgets/QuatRadialGauge';
import HalfRadial from '../widgets/HalfRadicalGauge'
import DataAna from '../../svg/DataAna.svg'


class DgData extends React.Component {
    constructor(props) {
        super(props)
        this.state= {
            dgData : null,
            dgKeys : null,
            dgID : null,
            range : null
        }
    }

    componentDidMount = () => {
        let firstKey;
        app.auth().onAuthStateChanged((user) => {
            if (user.uid) {
                let uID = user.uid 
                app.database().ref(`Users/${uID}/dgMeters`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        this.setState({dgKeys : snapShot.val()})
                        firstKey = Object.keys(snapShot.val())[0]
                        this.setState({dgID : firstKey})
                        app.database().ref(`meters/${firstKey}`).on('value', (snapShot) => {
                            if (snapShot.exists()) {
                                this.setState({dgData : snapShot.val()})
                                app.database().ref(`meterSettings/${firstKey}`).once('value', (snapShot) => {
                                    if (snapShot.exists()) {
                                        this.setState({range : snapShot.val()})
                                        // console.log(snapShot.val())
                                    } else {
                                        this.setState({range : {}})
                                    }
                                })
                            } else {
                                this.setState({dgData: "no Data"})
                            }
                        })
                    }
                })
            }
        })
       

    }
    render() {
        if (this.state.dgData) {
            var width = window.innerWidth;
            var rWidth = width*0.126;
            let b_HW = 70;
            let bc_HW = 65;
            return (
                <div style={{display:'flex', flex:1, height:'90vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, justifyContent:'center', maxHeight:70}}>
                            <div style={{display:'flex', flexDirection:'column', flex:1, marginLeft:120}}>
                                <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                    <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>{this.state.dgKeys[this.state.dgID]}</span>
                                </Typography>
                                <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>DETAILED</Typography>
                            </div>
                            <Card variant='elevation' elevation={4} style={{padding:'10px 30px', color:'#3F9DD4', borderRadius:20, backgroundColor:'#FCFCFC', width:100}}>
                                <FormControl style={{width:100}}>
                                    <InputLabel id="simple-select-label">Select Meter</InputLabel>
                                    <Select
                                    labelId="simple-select-label"
                                    id="simple-select"
                                    value={this.state.dgID}
                                    onChange={(e) => {this.setState({dgID : e.target.value})}}
                                    >
                                        {(Object.keys(this.state.dgKeys)).map((key, index) => 
                                            <MenuItem key={key} value={(Object.keys(this.state.dgKeys))[index]}>{this.state.dgKeys[Object.keys(this.state.dgKeys)[index]]}</MenuItem>
                                        )}
                                        
                                    </Select>
                                </FormControl>
                            </Card>
                        </div>
                        <div style={{display:'flex', flex:1}}>
                            <div style={{display:'flex', flex:1, flexDirection:'column', gap:2}}>
                                <div style={{display:'flex', position:'absolute', }}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid red", borderLeft:"7px dashed red", borderRight:"7px solid #FFD5D5", borderTop:'6px solid #FF5A5A', borderBottom:'7px solid #FF5A5A'}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.voltageAN} data={{units : "R-PHASE VOLTS"}} value={this.state.dgData.voltageAN} /> : null}
                                    </Card>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid white", borderLeft:"7px dashed #FFD5D5"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.currentA} data={{units : "R-PHASE AMPS",}} value={this.state.dgData.currentA}/> : null}
                                    </Card>
                                </div>
                                <div style={{display:'flex', position:'absolute', marginTop:rWidth}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid #F5FF60", borderLeft:"7px dashed #F5FF60"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.voltageBN} data={{units : "Y-PHASE VOLTS", }} value={this.state.dgData.voltageBN}/> : null }
                                    </Card>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid #F5FF60", borderLeft:"7px dashed #F5FF60"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.currentB} data={{units : "Y-PHASE AMPS",}} value={this.state.dgData.currentB}/> : null}
                                    </Card>
                                </div>
                                <div style={{display:'flex', position:'absolute', marginTop:2*rWidth}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid white", borderLeft:"7px dashed #B5B4FF"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.voltageCN} data={{units : "B-PHASE VOLTS",}} value={this.state.dgData.voltageCN}/> : null}
                                    </Card>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid white", borderLeft:"7px dashed white"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.currentC} data={{units : "B-PHASE AMPS",}} value={this.state.dgData.currentC}/> : null}
                                    </Card>
                                </div>
                            </div>
                            <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column'}}>
                                <div style={{flex:1, display:'flex', width:400, marginBottom:10}}>
                                <Card  style={{display:'flex',  backgroundColor: "#002035", flex:1, paddingLeft:50, paddingTop:10 }}>
                                            <div style={{position:'absolute'}}>
                                                <QuatRadial majorTicks={["E","","F"]} />
                                                <img src={require('../../svg/fuel.PNG')} width={20} height={20} style={{top:35, position:'absolute', right:80}} />
                                            </div>
                                            <div style={{position:'absolute', marginTop:130}}>
                                                <QuatRadial majorTicks={["L","","H"]} />
                                                <img src={require('../../svg/tank.PNG')} width={30} height={20} style={{top:35, position:'absolute', right:80}} />
                                            </div>
                                            <div style={{position:'absolute', marginLeft:200}}>
                                                <QuatRadial majorTicks={[40,90,140]} />
                                                <img src={require('../../svg/degree.PNG')} width={35} height={30} style={{top:35, position:'absolute', right:70}} />
                                            </div>
                                            <div style={{position:'absolute', marginTop:130, marginLeft:150}}>
                                                <div style={{position:'absolute'}}>
                                                    <HalfRadial majorTicks={[100,200,300,400,500,600,700,800,900]} />
                                                </div>
                                                <Typography style={{position:'absolute', marginLeft:105, marginTop:80, color:'white', borderRadius:5, backgroundColor:"#4c6271", paddingLeft:5, paddingRight:5}}>100</Typography>
                                            </div>
                                </Card>
                                </div>
                                <div style={{display:'flex', flex:1, justifyContent:'center',}}>
                                    <div style={{backgroundImage:`url(${rainbowRing})`, backgroundSize:'contain', padding:18, backgroundRepeat:'no-repeat'}}>
                                        <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                            {this.state.range ? <ReactRadialGauge range={this.state.range.kwPresentDemand} value={this.state.dgData.kwPresentDemand} /> : null}
                                        </Card>
                                    </div>
                                </div>
                                
                            </div>
                            <div style={{display:'flex', flex:.8, flexDirection:'column'}}>
                                <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', width:rWidth-10, height:rWidth-10, border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                    <div style={{backgroundImage:`url(${ring})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', display:'inline-flex', width:width*0.133, height:width*0.133, position:'absolute', marginLeft:-10, marginTop:-10,}}></div>
                                    <FrequencyGauge value={this.state.dgData.frequency}/>
                                </Card>
                                <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', width:rWidth-10, height:rWidth-10, border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                    <div style={{backgroundImage:`url(${yellowRing})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', display:'inline-flex', width:width*0.133, height:width*0.133, marginLeft:-10, marginTop:-10, position:'absolute'}}></div>
                                    <PFGauge value={this.state.dgData.powerFactor}/>
                                </Card>
                                <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', width:rWidth-10, height:rWidth-10, border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                    <div style={{backgroundImage:`url(${BlueRing})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', display:'inline-flex', width:width*0.133, height:width*0.133, marginLeft:-10, marginTop:-10, position:'absolute'}}></div>
                                    <ReactRadialGaugeSmall value={this.state.dgData.kvaPresentDemand} data={{units : "DEMAND IN KVA"}} />
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
            )
                        }
            else if (this.state.dgData === "no Data") {
                let b_HW = 70;
                 let bc_HW = 65;
                return (
                    <div style={{display:'flex', flex:1, height:'90vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                            <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>Feeder</span></Typography>
                            <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>DETAILED</Typography>
                        </div>
                        <div>No Data</div>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            } else if (this.state.dgData === null) {
                let b_HW = 70;
                let bc_HW = 65;
                return (
                    <div style={{display:'flex', flex:1, height:'90vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        {/* <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                            <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>Feeder</span></Typography>
                            <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>DETAILED</Typography>
                        </div> */}
                        <div>Loading Data</div>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            } 
        }
}

export default DgData