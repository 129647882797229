import React from 'react';
import Chart from 'react-apexcharts';
import app from '../../firebase/firebaseConfig'
import {
  Card,
  CardContent,
  Typography,
  useTheme
} from '@material-ui/core';

const MultiLineChart = (props) => {
  const theme = useTheme();
  const chart = {
    options: {
      noData: {
        text: "There's no data",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0
        },
      chart: {
        // background: theme.palette.background.paper,
        background : 'rgba(40, 44, 52, 0)',
        stacked: false,
        toolbar: {
          show: true
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        events: {
          beforeResetZoom: (ctx, opt) => {
            return {
              xaxis: {
                min: props.data.time.length -5 ,
                max: props.data.time.length
              }
            };
          },
          beforeZoom: (ctx, opt) => {
            return {
              xaxis: {
                min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
                max:
                  opt.xaxis.max > props.data.time.length
                    ? props.data.time.length
                    : opt.xaxis.max
              }
            };
          }
        }
      },
      colors: ['#0288D1', 'red', 'yellow', 'green'],
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: "#707070",
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: "#e6e5e8"
        }
      },
      markers: {
        size: 3,
        colors:['#0288D1', 'red', 'yellow', 'green'],
        // background: ,
        fillOpacity:1,
        strokeColors: ['#8a85ff', '#27c6db'],
        strokeWidth: 0,
        shape: 'circle',
        radius: 2,
        hover: {
          size: undefined,
          sizeOffset: 2
        }
      },
      stroke: {
        width: 1,
        curve: 'smooth',
        // lineCap: 'butt',
        dashArray: [0, 0,0]
      },
      theme: {
        mode: theme.palette.type
      },
      tooltip: {
        theme: theme.palette.type
      },
      xaxis: {
        axisBorder: {
          color: "#707070"
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: "#707070"
        },
        categories: props.data.time,
        tickPlacement: 'on',
        tickAmount: 'dataPoints',
        min: props.data.time.length -5 ,
        max: props.data.time.length,
        labels: {
          style: {
            colors: "#707070"
          }
        }
      },
      yaxis: [
        {
          // tickAmount:3,
          axisBorder: {
            show: false,
            color: "#e6e5e8"
          },
          axisTicks: {
            show: false,
            color: "#e6e5e8"
          },
          labels: {
            style: {
                fontSize: '8px',
              colors: "#707070"
            }
          }
        }
      ]
    },
    series: [
      {
        name: props.data.label[0],
        data: props.data.data[0] ? props.data.data[0] : []
      },
      {
        name: props.data.label[1],
        data: props.data.data[1] ? props.data.data[1] : []
      },
      {
        name: props.data.label[2],
        data: props.data.data[2] ? props.data.data[2] : []
      },
      {
        name: props.data.label[3] ? props.data.label[3] : [],
        data: props.data.data[3] ? props.data.data[3] : []
      },
    ],
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 20
      }
    },
    
  };

  return (
    <div style={{backgroundColor:'rgba(40, 44, 52, 0)', width:'80%'}}>
      <CardContent style={{padding:0}}>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <Typography
            variant="caption"
            color="black"
            gutterBottom={false}
            >
                {/* {props.data.text} */}
            </Typography>
            <div style={{display:'flex', flexDirection:'column', lineHeight:0}}>
            {props.data.text ? <Typography
                variant="caption"
                color="black"
                gutterBottom={false}
                >   
                  <div>{props.data.text}</div>
                  <div>~</div>
                    
                </Typography>   : <Typography
                variant="caption"
                color="black"
                gutterBottom={false}
                >KW</Typography>}
                
            </div>
            
          </div>
        
        <Chart
          type="line"
          height="230"
          {...chart}
        />
      </CardContent>
    </div>
  );
};

export default MultiLineChart;
