import React from 'react'
import {RadialGauge} from 'canvas-gauges'


class FrequencyGauge extends React.Component {
  componentDidMount () {
    const options = Object.assign({}, this.props, {
    renderTo: this.el,
    width: (window.innerWidth)*0.12,
    height: (window.innerWidth)*0.12,
    units: "FREQUENCY-HZ",
    minValue: 0,
    maxValue: 60,
    majorTicks: [
        "0",
        "10",
        "20",
        "30",
        "40",
        "50",
        "60"
    ],
    highlights: [
        
    ],
    minorTicks: 10,
    strokeTicks: false,
    colorPlate: "rgba(255,255,255, 0)",
    borderShadowWidth: 2,
    value: this.props.value ? this.props.value : 0,
    borders: false,
    needleType: "arrow",
    needleWidth: 2,
    colorNeedle: "#FF9D27",
    needleShadow: false,
    startAngle: 90,
    ticksAngle: 230,
    borderOuterWidth:10,
    colorNeedleCircleOuter: '#FF9D27',
    colorNeedleCircleOuterEnd: '#FF9D27',
    colorNeedleCircleInner: '#FF9D27',
    colorNeedleCircleInnerEnd: '#FF9D27',
    needleCircleSize: 7,
    // colorValueBoxRect:'black',
    colorNeedleShadowUp : '',
    highlightsLineCap:'square',
    needleCircleOuter: true,
    needleCircleInner: true,
    animation : false,
    animationDuration: 15,
    animationRule: "linear"
    })
    this.gauge = new RadialGauge(options).draw()
  }
  
  componentWillReceiveProps (nextProps) {
    this.gauge.value = nextProps.value
    this.gauge.update(nextProps)
  }

  render () {
    return (
            <canvas ref={(canvas) => {
                this.el = canvas
            }} />
    )
  }
}

export default FrequencyGauge