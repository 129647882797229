import React from 'react';
import { Link } from 'react-router-dom';
import {Typography, Button, Card, TextField, withStyles, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import DataAna from '../../svg/DataAna.svg'
import LiveTrends from '../../svg/icons/liveTrends.svg';
import breakerControls from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import app from '../../firebase/firebaseConfig'
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });


class BreakerControl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uID : null,
            checkedA : false,
            buttonA : true,
            buttonB : false,
            data : {},
            breakerID : null,
            open : false,
            passCode : null
        }
    }

    componentDidMount = () => {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                let uid = user.uid
                this.setState({uID : uid})
                app.database().ref(`Users/${uid}/breakerControl`).on('value' , (snapShot) => {
                    if (snapShot.exists()) {
                        this.setState({breakerID : snapShot.val()})
                        app.database().ref(`breakerControls/${snapShot.val()}`).on('value', (childSnapshot) => {
                            if (childSnapshot.exists()) {
                                this.setState({data : childSnapshot.val()})
                            }
                        })
                    }
                })
            }
        })
    }

    handleChange = (key) => {
        // let trigger = 
        app.database().ref(`breakerControls/${this.state.breakerID}/${key}`).set({
            name : this.state.data[key].name,
            status : 'orange',
            trigger : this.state.data[key].trigger === 0 ? 1:0
        })
    }

    handlePassCode = () => {
        app.database().ref(`Users/${this.state.uID}/breakerPass`).once("value", (snapShot) => {
            if(snapShot.exists()) {
                if (snapShot.val() === this.state.passCode) {
                    this.setState({checkedA : !this.state.checkedA, open: false})
                }
            }
        })
    }

    render() {
        let b_HW = 70;
        let bc_HW =65;
        return (
            <div style={{display:'flex', flex:1, height:'90vh'}}>
                <Dialog open={this.state.open} onClose={() => this.setState({open : false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter Pass Code to Access Breaker Controls</DialogTitle>
                    <DialogContent>
                        <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        onChange={(e) => this.setState({passCode : e.target.value})}
                        label="Pass Code"
                        type="password"
                        fullWidth
                    />      
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({open : false})} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handlePassCode} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                    <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                        <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                            <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>IO</span></Typography>
                        <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>MONITORING/CONTROL</Typography>
                    </div>
                    <div style={{display:'flex', justifyContent:"flex-end", paddingRight:20}}>
                        <FormControlLabel
                            control={<IOSSwitch checked={this.state.checkedA} onChange={() => this.setState({open : true})} name="checkedA" />}
                            label="Control Enable" labelPlacement="start"
                        />
                    </div>
                    
                    <div style={{display:'flex', flexWrap:'wrap', rowGap:30}}>
                        {/* Left Div */}
                        {Object.keys(this.state.data).map((key, index) => 
                            
                                <div key={key} style={{display:'flex', flex:'0 0 50%', maxHeight:70}}>
                                    <div style={{display:'flex', flex:1, flexDirection:'row', height:50, width:'50%', justifyContent:'center', alignItems:'center', gap:30}}>
                                        <Card elevation={6} style={{padding:"10px 10px" , width:200 , borderRadius:20, textAlign:'center'}}>{this.state.data[key]['name']}</Card>
                                        <Card elevation={6} onClick={(e) => this.state.checkedA ? this.handleChange(key) : null}  component={IconButton}>
                                            <PowerSettingsNewRoundedIcon style={{fill : this.state.data[key]['status']}}></PowerSettingsNewRoundedIcon>
                                        </Card>
                                        
                                    </div>
                                </div>
                                )
                            
                        }
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                </div>
            </div>
        )
    }
}

export default BreakerControl