import './App.css';
import React from 'react'
import Routes from "./Routes";
import {withRouter} from 'react-router'

function App() {
  return (
        <Routes />
  );
}

export default withRouter(App);
