import React from 'react';
import { Link } from 'react-router-dom';
import {Typography, Button, Card} from '@material-ui/core'
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import LiveTrends from '../../svg/icons/liveTrends.svg';
import BreakerControl from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import ReactRadialGauge from '../widgets/RadialGauge';
import PFGauge from '../widgets/PFGauge';
import FrequencyGauge from '../widgets/FrequencyGauge'
import ReactRadialGaugeSmall from '../widgets/RadialGaugeS';
import rainbowRing from '../../svg/rainbowRing.svg';
import MeterBG from '../../svg/meterBG.svg'
import pMeterBG from '../../svg/pMeterBG.svg'
import ring from '../../svg/ring.svg'
import yellowRing from '../../svg/yellowRing.svg'
import BlueRing from '../../svg/BlueRing.svg'
import app from '../../firebase/firebaseConfig';
import DataAna from '../../svg/DataAna.svg'


class Incomer extends React.Component {
    constructor(props) {
        super(props)
        this.state= {
            incomerData : null,
            range : null,
            resize : false

        }
    }

    componentDidMount = () => {
        app.database().ref(`meters/${localStorage.getItem('i_id')}`).on('value', (snapShot) => {
            if (snapShot.exists()) {
                this.setState({incomerData : snapShot.val()})
                app.database().ref(`meterSettings/${localStorage.getItem('i_id')}`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        this.setState({range : snapShot.val()})
                        // console.log(snapShot.val())
                    } else {
                        this.setState({range : {}})
                    }
                })
            } else {
                this.setState({incomerData: "no Data"})
            }
        })

    }
    render() {

        if (this.state.incomerData) {
            var width = window.innerWidth;
            var rWidth = width*0.126;
            const b_HW = 70;
            const bc_HW = 65;
            return (
                <div style={{display:'flex', flex:1, height:'90vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                            <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>INCOMER</span></Typography>
                            <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>DETAILED</Typography>
                        </div>
                        <div style={{display:'flex', flex:1}}>
                            <div style={{display:'flex', flex:1, flexDirection:'column', gap:2, alignItems:'center'}}>
                                <div style={{display:'flex', position:'absolute', }}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid red", borderLeft:"7px dashed red", borderRight:"7px solid #FFD5D5", borderTop:'6px solid #FF5A5A', borderBottom:'7px solid #FF5A5A'}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.voltageAN} data={{units : "R-PHASE VOLTS"}} value={this.state.incomerData.voltageAN} /> : null}
                                    </Card>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid white", borderLeft:"7px dashed #FFD5D5"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.currentA}  data={{units : "R-PHASE AMPS"}} value={this.state.incomerData.currentA} /> : null}
                                    </Card>
                                </div>
                                <div style={{display:'flex', position:'absolute', marginTop:width*0.13666}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid #F5FF60", borderLeft:"7px dashed #F5FF60"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.voltageBN}  data={{units : "Y-PHASE VOLTS"}} value={this.state.incomerData.voltageBN}/> : null}
                                    </Card>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid #F5FF60", borderLeft:"7px dashed #F5FF60"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.currentB}  data={{units : "Y-PHASE AMPS"}} value={this.state.incomerData.currentB}/> : null }
                                    </Card>
                                </div>
                                <div style={{display:'flex', position:'absolute', marginTop:width*0.13666*2}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid white", borderLeft:"7px dashed #B5B4FF"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.voltageCN} data={{units : "B-PHASE VOLTS"}} value={this.state.incomerData.voltageCN}/> : null }
                                    </Card>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{position:'absolute', width:rWidth, height:rWidth, marginTop:-10, marginLeft:-10, borderRadius:400, border:"7px solid white", borderLeft:"7px dashed white"}}></div>
                                        {this.state.range ? <ReactRadialGaugeSmall range={this.state.range.currentC}  data={{units : "B-PHASE AMPS"}} value={this.state.incomerData.currentC}/> : null}
                                    </Card>
                                </div>
                            </div>
                            <div style={{display:'flex', flex:1, justifyContent:'center'}}>
                                <div style={{backgroundImage:`url(${rainbowRing})`, backgroundSize:'contain', padding:18, backgroundRepeat:'no-repeat', marginTop:90}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        {this.state.range ? <ReactRadialGauge range={this.state.range ? this.state.range.kwPresentDemand : null} value={this.state.incomerData.kwPresentDemand} /> : null}
                                    </Card>
                                </div>
                            </div>
                            <div style={{display:'flex', flex:.5, flexDirection:'column',}}>
                                <div style={{position:'absolute', marginLeft:-width*0.0866 }}>
                                <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                    <div style={{backgroundImage:`url(${ring})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', display:'inline-flex', width:width*0.133, height:width*0.133, position:'absolute', marginLeft:-10, marginTop:-10,}}></div>
                                    <FrequencyGauge  value={this.state.incomerData.frequency}/>
                                </Card>
                                </div>
                                <div style={{ position:'absolute', marginLeft:-width*0.04 , marginTop:width*0.0933+20}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{backgroundImage:`url(${yellowRing})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', display:'inline-flex', width:width*0.133, height:width*0.133, marginLeft:-10, marginTop:-10, position:'absolute'}}></div>
                                        <PFGauge value={this.state.incomerData.powerFactor} />
                                    </Card>
                                </div>
                                <div style={{ position:'absolute',marginLeft:-width*0.08 , marginTop:width*0.2133+20}}>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <div style={{backgroundImage:`url(${BlueRing})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', display:'inline-flex', width:width*0.133, height:width*0.133, marginLeft:-10, marginTop:-10, position:'absolute'}}></div>
                                        <ReactRadialGaugeSmall value={this.state.incomerData.kvaPresentDemand} data={{units : "DEMAND IN KVA"}} />
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
            )
                        }
            else if (this.state.incomerData === "no Data") {
                const b_HW = 70;
                const bc_HW = 65;
                return (
                    <div style={{display:'flex', flex:1, height:'90vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                            <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>INCOMER</span></Typography>
                            <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>DETAILED</Typography>
                        </div>
                        <div>No Data</div>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            } else if (this.state.incomerData === null) {
                const b_HW = 70;
                const bc_HW = 65;
                return (
                    <div style={{display:'flex', flex:1, height:'90vh'}}>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                            <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>INCOMER</span></Typography>
                            <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>DETAILED</Typography>
                        </div>
                        <div>Loading Data</div>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            }
    }
}

export default Incomer