import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Home from "./Home";
import Signup from './Auth/signup'
import Dashboard from './dashboard/Dashboard';



export default function Routes() {
    return (
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route exact path='/dashboard'>
                <Dashboard />
            </Route>
            <Route exact path='/signup'>
                <Signup />
            </Route>
        </Switch>
    )
}