import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Typography, Button, Card} from '@material-ui/core'
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import LiveTrends from '../../svg/icons/liveTrends.svg';
import BreakerControl from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import ReactRadialGauge from '../widgets/RadialGaugeS';
import MeterBG from '../../svg/pMeterBG.svg';
import app from '../../firebase/firebaseConfig'
import rainbowRing from '../../svg/rainbowRing.svg'
import DataAna from '../../svg/DataAna.svg'

class FeederMonitoring extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            feeders : null,
            feedersData : null,
            range : null,
            load : false
        }
    }
    componentDidMount = () => {
        app.auth().onAuthStateChanged(async (user) => {
            if (user) {
                var userID =  user.uid
                await app.database().ref(`Users/${userID}/feederMeters`).on("value" , (snapShot) => {
                    if (snapShot.exists()) {
                        this.setState({feeders : snapShot.val()})
                        snapShot.forEach((childSnapshot) => {
                            // console.log(childSnapshot.key)
                            app.database().ref(`meters/${childSnapshot.key}`).on('value', (snapshot) => {
                                if (snapshot.exists()) {
                                    this.setState({'feedersData' : {...this.state.feedersData, [childSnapshot.key] : snapshot.val() }})
                                    app.database().ref(`meterSettings/${childSnapshot.key}/kwPresentDemand`).once('value', (newSnapShot) => {
                                        if (newSnapShot.exists()) {
                                            this.setState({range : {...this.state.range, [childSnapshot.key] : newSnapShot.val()}})
                                            // console.log(childSnapshot.key, newSnapShot.val())
                                        } else {
                                            // this.setState({range : {}})
                                        }
                                    })
                                    this.setState({load : true})
                                }
                            })
                        })
                    }
                })
            }
        })
        
    }

    render() {
        
        var width = window.innerWidth;
        
        if (!this.state.feedersData) {
            // console.log(this.state)
            return (
                <div></div>
            )
        }
        else {
            const b_HW = 70;
            const bc_HW =65;
        return (
            <div style={{display:'flex', flex:1}}>
                <div style={{display:'flex', flex:1,}}>
                    {this.state.feeders !== null ? 
                    <div style={{display:'flex', flexWrap:'wrap', flex:1, justifyContent:'space-around'}}>
                        {Object.keys(this.state.feeders).map((key, index) => 
                            <div key={key} style={{display:'flex', flexDirection:'column', width:'fit-content'}}>
                                <Typography variant='caption' style={{paddingLeft:20}} display='inline' gutterBottom={true}>
                                    <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>{this.state.feeders[Object.keys(this.state.feeders)[index]]}</span></Typography>
                                <Button component={Link} 
                                style={{backgroundImage:`url(${rainbowRing})`, backgroundSize:'contain', padding:18, backgroundRepeat:'no-repeat', marginTop:90}}
                                onClick={() => {localStorage.setItem('f_id', Object.keys(this.state.feeders)[index]);
                                localStorage.setItem('f_n', this.state.feeders[Object.keys(this.state.feeders)[index]]) }} 
                                to='/dashboard/feederMonitor/detailed'>
                                    <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                        <ReactRadialGauge range={this.state.range ? this.state.range[Object.keys(this.state.feeders)[index]] : null} value={this.state.feedersData[Object.keys(this.state.feeders)[index]] ? this.state.feedersData[Object.keys(this.state.feeders)[index]].kwPresentDemand : 0}></ReactRadialGauge> 
                                    </Card>
                                    <div style={{position:'absolute', marginBottom:140, width:100,}}>
                                        <Typography style={{fontSize:12, color:"#9b9b9b"}} align='center'>KWH</Typography>
                                    </div>
                                    <div style={{position:'absolute', marginBottom:80, width:100, border:'4px solid #646464', borderRadius:6, backgroundColor:'#babab2'}}>
                                        <Typography align='center'>{this.state.feedersData[Object.keys(this.state.feeders)[index]]  ? parseFloat(this.state.feedersData[Object.keys(this.state.feeders)[index]].activeEnergy).toFixed(3) : null}</Typography>
                                    </div>
                                </Button>
                            </div>
                        )}
                        
                    </div> : null }
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                </div>

            </div>
        )}
    }
}

export default withRouter(FeederMonitoring);