import React from 'react'

class Signup extends React.Component {
    constructor(props) {
        super(props)

    }

    componentDidMount() {

    }

    render() {
        return(
            <div>
                SignUp
            </div>
        )
    }
}

export default Signup