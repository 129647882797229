import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, FormControl, Select, InputLabel, MenuItem, Typography } from '@material-ui/core';
import { readString } from 'react-papaparse';
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import LiveTrends from '../../svg/icons/liveTrends.svg';
import BreakerControl from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import MultiLineChart from '../widgets/multiLineChart';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DataAna from '../../svg/DataAna.svg'
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  

import app from '../../firebase/firebaseConfig';

class Trends extends React.Component {
    constructor(props) {
        super(props);
        this.interval = null;
        this.state = {
            meterData : null,
            selectedDate : new Date(),
            chart1Lables : ['KW_B', 'KW_R', 'KW_Y', 'kwPresentDemand'],
            chart2Lables : ['voltageAN', 'voltageBN', 'voltageCN'],
            chart3Lables : ['currentA', 'currentB', 'currentC'],
            chart1Data : [],
            chart2Data : [],
            chart3Data : [],
            time : [],
            meters : {},
            meterID : localStorage.getItem('i_id'),
            dateShow : true,
            downLoadUrl : "",
        }

    }

    componentDidMount = () => {
        
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                app.database().ref(`Users/${user.uid}`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        let data = {}
                        snapShot.forEach((childSnapshot) => {
                            if (childSnapshot.key !== 'users') {
                                childSnapshot.forEach((mainSnapshot) => {
                                    data[mainSnapshot.key] = mainSnapshot.val()
                                })
                            }
                        })
                        
                        this.setState({meters : data})
                    }
                })
                this.updateChartData(this.getDateFormat(new Date(this.state.selectedDate)), localStorage.getItem('i_id'))
                this.getKVTimer = setInterval(this.getKvaValue, 3000)
                // this.updateChartData(this.getDateFormat(new Date(this.state.selectedDate)), localStorage.getItem('i_id'))
            }
        })
        
    }

    componentWillUnmount = () => {
        clearInterval(this.getKVTimer)
        this.getKVTimer = null;
    }

    getKvaValue = () => {
        app.database().ref(`meters/${this.state.meterID}/kvaPresentDemand`).once('value', (snapShot) => {
            if (snapShot.exists()) {
                app.database().ref(`meterSettings/${this.state.meterID}/availableMaxDemand`).once('value', (childSnapshot) => {
                    if (childSnapshot.exists()) {
                        this.setState({'KVA' : parseFloat(((snapShot.val())/childSnapshot.val())*100).toFixed(1)})
                    }else {
                        this.setState({'KVA' : parseFloat(Math.floor(snapShot.val())).toFixed(1)})
                    }
                })
                
            }
        })
    }

    componentDidUpdate = () => {

    }
    

    handleRealtimeChart = (e) => {
        this.setState({downLoadUrl : ""})
        let time = []
        let [KW_B, KW_R, KW_Y, kwPresent, chart1Data, chart2Data, chart3Data, voltageAN, voltageBN, voltageCN, currentA] = [[], [], [], [], [], [], [], [], [], [], []];
        let [currentB, currentC] = [[], []]
        let onValueChange = (snapShot) => {
            if (snapShot.exists()) {
                let data = snapShot.val()
                var date = new Date()
                // date.setSeconds(result.data[i][21]/1000)
                var hours = date.getHours();
                // Minutes part from the timestamp
                var minutes = date.getMinutes();
                // Seconds part from the timestamp
                var seconds = date.getSeconds();
                date = `${hours}:${minutes}:${seconds}`
                time.push(date)
                KW_B.push(data['KW_B'])
                KW_R.push(data['KW_R'])
                KW_Y.push(data['KW_Y'])
                kwPresent.push(data['kwPresentDemand'])
                voltageAN.push(data['voltageAN'])
                voltageBN.push(data['voltageBN'])
                voltageCN.push(data['voltageCN'])
                currentA.push(data['currentA'])
                currentB.push(data['currentB'])
                currentC.push(data['currentC'])
                if (KW_B.length > 60) {
                    KW_B.shift();
                    KW_R.shift(); KW_Y.shift(); time.shift();
                    voltageAN.shift(); voltageBN.shift(); voltageCN.shift();
                    currentA.shift(); currentB.shift(); currentC.shift();
                }

                chart1Data[0] = (KW_B)
                chart1Data[1] = (KW_R)
                chart1Data[2] = (KW_Y)
                chart1Data[3] = (kwPresent)

                chart2Data[0] = voltageAN
                chart2Data[1] = voltageBN
                chart2Data[2] = voltageCN

                chart3Data[0] = currentA
                chart3Data[1] = currentB
                chart3Data[2] = currentC

                
            }
            
            this.setState({time: time, chart1Data: chart1Data, chart2Data: chart2Data, chart3Data: chart3Data})
        }
        if (e.target.checked) {
            this.setState({dateShow: false})
            this.interval = setInterval(() => app.database().ref(`meters/${this.state.meterID}`).once('value', onValueChange), 5000);          
        }else {
            this.setState({dateShow : true})
            clearInterval(this.interval)
            let [time, chart1Data, chart2Data, chart3Data] = [[], [], [], []];
            this.setState({time: time, chart1Data: chart1Data, chart2Data: chart2Data, chart3Data: chart3Data})
            this.updateChartData(this.getDateFormat(this.state.selectedDate), this.state.meterID)
        }

    }
    updateChartData = (filename, meterID) => {
        app.storage().ref(`${meterID}/${filename}.csv`).getDownloadURL().then((snapshot) => {
            this.setState({downLoadUrl : snapshot})
            var that = this
            var xhr = new XMLHttpRequest();
            let url = `${snapshot}`;
            xhr.responseType = 'text'; 
            xhr.onload = () => {
                var csv= xhr.response;
                var result = readString(csv)
                var labels = []
                let index = {}
                for (let i=0; i<result.data[0].length; i++) {
                    labels.push(result.data[0][i])
                    index[result.data[0][i]] = i
                }
                // let  = []
                let [KW_B, KW_R, KW_Y, kwPresent, chart1Data, chart2Data, chart3Data, voltageAN, voltageBN, voltageCN, currentA] = [[], [], [], [], [], [], [], [], [], [], []];
                let [currentB, currentC] = [[], []];
                let time = []
                for (let i=0; i<result.data.length; i++) {
                    if (i !== 0) {
                        if (i !== result.data.length-1) {
                            var date = new Date(parseInt(result.data[i][21]))
                            // date.setSeconds(result.data[i][21])
                            var hours = date.getHours();
                            // Minutes part from the timestamp
                            var minutes = date.getMinutes();
                            // Seconds part from the timestamp
                            // var seconds = date.getSeconds();
                            date = `${hours}:${minutes}`
                            time.push(date)
                            KW_B.push(result.data[i][index['KW_B']])
                            KW_R.push(result.data[i][index['KW_R']])
                            KW_Y.push(result.data[i][index['KW_Y']])
                            kwPresent.push(result.data[i][index['kwPresentDemand']])
                            voltageAN.push(result.data[i][index['voltageAN']])
                            voltageBN.push(result.data[i][index['voltageBN']])
                            voltageCN.push(result.data[i][index['voltageCN']])
                            currentA.push(result.data[i][index['currentA']])
                            currentB.push(result.data[i][index['currentB']])
                            currentC.push(result.data[i][index['currentC']])
                        }
                    }
                }
                chart1Data = [KW_B , KW_R, KW_Y, kwPresent]
                chart2Data = [voltageAN, voltageBN, voltageCN]
                chart3Data = [currentA, currentB, currentC]
                that.setState({time: time, chart1Data: chart1Data, chart2Data: chart2Data, chart3Data: chart3Data,})
                // }
                
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch((error) => {
            // Handle any errors
            let [time, chart1Data, chart2Data, chart3Data] = [[], [], [], []];
            this.setState({time: time, chart1Data: chart1Data, chart2Data: chart2Data, chart3Data: chart3Data})
          });
        }
    

    handleDateChange = (date) => {
        this.setState({selectedDate : new Date(date)})
        let newSelectedDate = this.getDateFormat(new Date(date))
        this.updateChartData(newSelectedDate, this.state.meterID)
    }

    getDateFormat = (date) => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        return date =yyyy + mm + dd;
    }

    getTodayDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        return today =yyyy + mm + dd;
    }

    render() {
        let b_HW = 70;
        let bc_HW = 65
        return (
            <div style={{display:'flex', flex:1}}>
                <div style={{display:'flex', flex:1, flexDirection:'column', maxHeight:'100vh'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Card variant='elevation' elevation={4} style={{padding:'0px 30px', color:'#3F9DD4', borderRadius:20, backgroundColor:'#FCFCFC', display:'flex'}}>
                            {this.state.dateShow ? <MuiPickersUtilsProvider utils={MomentUtils} justifyContent='flex-end'>
                                <Grid container>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/DD/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Select Date"
                                        value={this.state.selectedDate}
                                        onChange={this.handleDateChange}
                                        style={{maxWidth:150}}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider> : null}
                            <FormControlLabel
                                
                                value="top"
                                control={<Switch color="primary" onChange={(e) => this.handleRealtimeChart(e)} />}
                                label="RealTime"
                                labelPlacement="top"
                            />
                        </Card>
                        <Card variant='elevation' elevation={4} style={{padding:'10px 30px', color:'#3F9DD4', display:'flex', flexDirection:'column', borderRadius:20, backgroundColor:'#FCFCFC'}}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Select Meter</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.meterID}
                                onChange={(e) => {this.setState({meterID : e.target.value}, this.updateChartData(this.getDateFormat(new Date(this.state.selectedDate)), e.target.value))}}
                                >
                                    {(Object.keys(this.state.meters)).map((key, index) => 
                                        <MenuItem key={key} value={(Object.keys(this.state.meters))[index]}>{this.state.meters[Object.keys(this.state.meters)[index]]}</MenuItem>
                                    )}
                                    
                                </Select>
                            </FormControl>
                            {this.state.downLoadUrl !== "" ? <Card component={Button} style={{marginTop:20, height:30, textAlign:'center', backgroundColor:"#3F9DD4"}} onClick={() => {window.open(this.state.downLoadUrl, "_blank")}} >
                                <Typography variant="caption" style={{color:'white'}}>Download</Typography>
                            </Card> : null }
                        </Card>
                    </div>
                    <div style={{display:'flex', flex:1, height:window.innerHeight}}>
                        <div style={{display:'flex', flex:.7, flexDirection:'column'}}>
                            <div>
                                <MultiLineChart data={{"time" : this.state.time, "data" : this.state.chart1Data, "label" : this.state.chart1Lables}} />
                            </div>
                            <div style={{display:'flex', flex:1}}>
                                <MultiLineChart data={{"time" : this.state.time, "data" : this.state.chart2Data, "label" : this.state.chart2Lables, 'text' : "V"}} />
                                <MultiLineChart data={{"time" : this.state.time, "data" : this.state.chart3Data, "label" : this.state.chart3Lables, 'text' : 'A'}} />
                            </div>
                        </div>
                        <div style={{display:'flex', flex:.3}}>
                            <svg>
                                <g id="Group_9780" data-name="Group 9780" transform="translate(-890.222 -180.214)">
                                    <text id="_60_" data-name="60%" transform="translate(959.214 295.292)" fill="#999" fontSize="41.826" fontFamily="Lato-Light, Lato" fontWeight="300"><tspan x="0" y="0" fill="#000">{this.state.KVA}</tspan><tspan y="0" fill="#999">%</tspan></text>
                                    <circle id="Ellipse_994" data-name="Ellipse 994" cx="83.12" cy="83.12" r="83.12" transform="translate(895.11 347.505) rotate(-77.666)" fill="none" stroke="#d9d9d9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    <circle id="Ellipse_995" data-name="Ellipse 995" cx="74.74" cy="74.74" r="74.74" transform="matrix(0.844, -0.536, 0.536, 0.844, 890.912, 261.009)" fill="none" stroke="#d9d9d9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                    <circle id="Ellipse_996" data-name="Ellipse 996" cx="66.849" cy="66.849" r="66.849" transform="translate(927.218 217.21)" fill="none" stroke="#d9d9d9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5"/>
                                    <path id="Path_5589" data-name="Path 5589" d="M1066.933,324.051a83.12,83.12,0,1,1-102.8-117.537" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    <path id="Path_5590" data-name="Path 5590" d="M1034.305,347.055a74.745,74.745,0,1,1-6.814-129.846" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                    <text id="KVA" transform="translate(977.64 323.4)" fontSize="19" fontFamily="MyriadPro-Regular, Myriad Pro" textDecoration="underline"><tspan x="0" y="0">KVA</tspan></text>
                                </g>
                            </svg>
                            {/* </div> */}
                        </div>
                        
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                </div>
            </div>
        )
    }
}

export default Trends