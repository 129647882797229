import { Typography, TextField, FormControl, InputAdornment, Button, ButtonBase } from '@material-ui/core'
import React from 'react'
import homeBG from '../svg/homeBG.svg'
import Login from '../svg/Login.svg'
import app from '../firebase/firebaseConfig'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withRouter, Switch, Link } from 'react-router-dom'
import Dashboard from '../dashboard/Dashboard'
import { Redirect } from 'react-router'
import './LandingHome.css';

const location = {
    hash:null,
    pathname: '/dashboard',
    state: 'dashboard'
  }

class LandingHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            matches: window.matchMedia("(min-width: 768px)").matches,
            userAuthenticated : false,
            email : null,
            password : null,
            showPassword : false,
            alert : false,
            alertMsg : null,
            severity : null,
            redirect : false
        };
    }

    componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({userAuthenticated : true})
            } else {
                this.setState({userAuthenticated : false})
            }
        })

    }

    handleLogin = async () => {
        var that = this;
        app.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                that.setState({severity:'error', alertMsg:'Wrong Password', alert:true})
            } else {
                that.setState({severity:'error', alertMsg: errorMessage, alert:true})
            }

        }).then(() => {
            this.setState({redirect : true})
        })
    }

    handleClickShowPassword = () => {
        this.setState({showPassword : !this.state.showPassword})
    }

    handleDashboardRedirect = () => {
        this.setState({redirect : true})
    }
    handleImmortal = () => {
        window.open('https://immortalt.dev/', '_blank');
    }

    render() {

        return(
            <div style={{display:'flex', flex:1}}>
                {!this.state.redirect ?
                <div style={this.state.matches ? {display:'flex', flex:1} : {display:'flex', flex:1, flexDirection:'column'}}>
                    <div style={{display:'flex', flex:6, flexDirection:'column', marginTop:50}}>
                        <Typography variant='h4' style={{color:'red', display:'flex', marginLeft:30}} gutterBottom><span style={{color:'#00F400'}}>Conserve </span> Today, <span style={{color:'#00F400'}}>Use </span> Tomorrow..</Typography>
                        <Typography style={{marginLeft:80}} align='justify' variant='body1' gutterBottom>Our energy management solution is a powerful tool for Manufacturing Industries and buildings
                            to fulfill their energy monitoring and optimization needs.
                            The solution is designed to work in an island (behind a single meter) or distributed setup. You can
                            connect any energy production, consumption, or storage system independent of brand and 
                            communication protocol. By using pre-configured asset types you can quickly set up your smart
                            energy management system resulting  in increased energy efficiency and cost-savings.</Typography>
                        <Typography variant='h5' align='right'>“ Take Back Control of your <span style={{color:'#00F400'}}>Energy</span> Bills. ”</Typography>
                    </div>
                    <div style={{display:'flex', flex:4}}>
                        <div style={{backgroundImage: `url(${Login})`, display:'inline-block', zIndex:1000, backgroundRepeat:'no-repeat', width:'100%', opacity:1}}>
                            {this.state.userAuthenticated ? 
                            <div  style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:470, height:470}}>
                            <Button variant='text' color='primary' style={{borderRadius:50, width:200, padding:10, marginTop:16, marginBottom:8, background:'transparent linear-gradient(180deg, #FFFFFF 8%, #F1F2F8 23%, #CDD1E5 66%, #C0C5DF 90%) 0% 0% no-repeat padding-box', boxShadow:'0px 6px 3px #00000059', border:'0.6265419721603394px solid #FFFFFF', color:'#3B3D55'}} onClick={this.handleDashboardRedirect}>Go to Dashboard</Button> 
                            <Button variant='text' color='primary' style={{borderRadius:50, width:200, padding:10, marginTop:16, marginBottom:8, background:'transparent linear-gradient(180deg, #FFFFFF 8%, #F1F2F8 23%, #CDD1E5 66%, #C0C5DF 90%) 0% 0% no-repeat padding-box', boxShadow:'0px 6px 3px #00000059', border:'0.6265419721603394px solid #FFFFFF', color:'#3B3D55'}} onClick={this.props.logout}>Log Out</Button>
                            </div>
                            : 
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:470, height:470}}>
                                <TextField variant='outlined' autoComplete={"username"}  label='Email Address' style={{marginTop:50, marginBottom:8, width:230}} onChange={(event) => this.setState({email : event.target.value})} />
                                <FormControl>
                                    <TextField autoComplete={"current-password"} type={this.state.showPassword ? 'text' : 'password'} style={{width:230}} variant='outlined' label='Password' onChange={(event) => this.setState({password : event.target.value})} 
                                        InputProps = {{endAdornment:
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        }}
                                        
                                    />
                                </FormControl>
                                <Button variant='text' color='primary' style={{borderRadius:50, width:150, padding:10, marginTop:16, marginBottom:8, background:'transparent linear-gradient(180deg, #FFFFFF 8%, #F1F2F8 23%, #CDD1E5 66%, #C0C5DF 90%) 0% 0% no-repeat padding-box', boxShadow:'0px 6px 3px #00000059', border:'0.6265419721603394px solid #FFFFFF', color:'#3B3D55'}} onClick={this.handleLogin}>Log in</Button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                : <Redirect to={location} />}
                <div style={{position:'absolute', bottom:0}}>
                    <img src={homeBG} width='100%' height='auto'></img>
                </div>
                <div style={{position:'absolute', bottom:10, width:"95%", display:'flex', flex:1}}>
                    <Typography variant="caption" style={{textAlign:'center', flex:1}}>Tesla One Eye - © 2021. All Rights Reserved. | Powered By <ButtonBase variant="text" onClick={this.handleImmortal} ><Typography style={{fontSize:12}}>IMMORTAL DEV</Typography></ButtonBase></Typography>
                </div>
            </div>
        )
    }
}


export default withRouter(LandingHome)