import React from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom';
import {Button, Typography, Card} from '@material-ui/core';
import app from '../firebase/firebaseConfig';
import ReactRadialGauge from './widgets/RadialGauge'
import dashRing from '../svg/dashRing.svg';
import FeederMonitor from '../svg/icons/feederMonitor.svg';
import DgData from '../svg/icons/dgData.svg';
import BreakerControl from '../svg/icons/breakerControl.svg';
import IncomerDetail from '../svg/icons/incomerDetail.svg';
import LiveTrends from '../svg/icons/liveTrends.svg';
import MeterSettings from '../svg/icons/meterSettings.svg';
import DataAna from '../svg/DataAna.svg'



class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect : false,
            incomerData : {
                kwPresentDemand : 0,
                activeEnergy : 0
            },
            range: null
        }
    }

    componentDidMount = () => {
        localStorage.setItem('f_id', null)
        app.auth().onAuthStateChanged((user) => {
            if (!user) {
                this.setState({redirect : true})
            } else {
                app.database().ref(`Users/${user.uid}/incomerMeter`).once('value', (snapshot) => {
                    if(snapshot.exists()) {
                        let incomerID = Object.keys(snapshot.val())[0]
                        localStorage.setItem("i_id", incomerID)
                        app.database().ref(`meters/${incomerID}/kwPresentDemand`).on('value', (snapShot) => {
                            if (snapShot.exists()) {
                                this.setState({incomerData : {...this.state.incomerData, kwPresentDemand : snapShot.val()}})
                            }
                        })
                        app.database().ref(`meters/${incomerID}/activeEnergy`).on('value', (snapShot) => {
                            if (snapShot.exists()) {
                                this.setState({incomerData : {...this.state.incomerData, activeEnergy : snapShot.val()}})
                            }
                        })
                        app.database().ref(`meterSettings/${incomerID}/kwPresentDemand`).once('value', (snapShot) => {
                            if (snapShot.exists()) {
                                this.setState({range : snapShot.val()})
                            }else {
                                this.setState({range : {}})
                            }
                        })
                    }
                })
                
            }
        })


    }

    render() {
        if (!this.state.redirect) {
            return(
                <div style={{display:'flex', flex:1, flexDirection:'column', maxHeight:(window.innerHeight - 600)}}>
                    <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:100}}>
                        <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                            <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>INCOMER</span></Typography>
                        <Typography variant='caption' align='center' gutterBottom={false} style={{ fontSize:24, fontWeight:500}}>INFOGRAPHIC</Typography>
                    </div>
                    <div style={{display:'flex', flex:1, maxHeight:600}}>
                        <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center'}}>
                            <div style={{display:'flex', flex:1, flexDirection:'column', gap:30}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                    <Typography variant='h5' style={{fontWeight:600, textAlign:'end' }}><span style={{color:'#FF640A'}}>FEEDER </span> MONITORING </Typography>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #FFF200', borderLeft:'6px dotted #FFF200'}}>
                                        <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                            background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                            width:100, height:100,
                                            borderRadius:130,
                                            boxShadow:'10px 8px 8px #00000029',
                                            border:'3px solid #E6E6E6'
                                        }} ><img src={FeederMonitor}></img></Link>
                                    </div>
                                </div>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                    <Typography variant='h5' style={{fontWeight:600, textAlign:'end'}}><span style={{color:'#E50586'}}>INCOMER </span> DETAILED </Typography>
                                    <div style={{display:'flex', marginRight:50, justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #FF0000', borderLeft:'6px dotted #FF0000'}}>
                                        <Link component={Button} to="/dashboard/incomerDetailed" variant='contained' style={{
                                            background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                            width:100, height:100,
                                            borderRadius:130,
                                            boxShadow:'10px 8px 8px #00000029',
                                            border:'3px solid #E6E6E6'
                                        }} ><img src={IncomerDetail}></img></Link>
                                    </div>
                                </div>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                    <Typography variant='h5' style={{fontWeight:600, textAlign:'end'}}><span style={{color:'#E50586'}}>DG </span> DATA </Typography>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #FF00BF', borderLeft:'6px dotted #FF00BF'}}>
                                        <Link component={Button} to="/dashboard/dgData" variant='contained' style={{
                                            background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                            width:100,
                                            height:100,
                                            borderRadius:130,
                                            boxShadow:'10px 8px 8px #00000029',
                                            border:'3px solid #E6E6E6'
                                        }} ><img src={DgData}></img></Link>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center'}}>
                            <div style={{backgroundImage: `url(${dashRing})`, backgroundSize:'100%', display:'inline-flex', zIndex:1000, backgroundRepeat:'no-repeat', width:400, height:320, justifyContent:'center', alignItems:'center', opacity:1}}>
                                <Card variant='elevation' style={{background:'linear-gradient(145deg, rgb(226, 225, 230),rgb(255, 255, 255), rgb(255, 255, 255))', border:'2px solid #E6E6E6 ',borderRadius:'50%', boxShadow:'7px 7px 18px -4px rgb(0 0 0 / 20%), 20px 20px 17px 0px rgb(0 0 0 / 14%), 4px 4px 4px 0px rgb(0 0 0 / 12%),  36px 36px 10px 0px rgb(0 0 0 / 12%)'}}>
                                    <div style={{position:'relative'}}>
                                        {this.state.range ? <ReactRadialGauge range={this.state.range ? this.state.range : null} size={250} value={this.state.incomerData ? parseFloat(this.state.incomerData.kwPresentDemand).toFixed(3) : null}/> : null }
                                    </div>
                                    
                                </Card>
                                <div style={{position:'absolute', marginBottom:140, width:100,}}>
                                    <Typography style={{fontSize:12, color:"#9b9b9b"}} align='center'>KWH</Typography>
                                </div>
                                {this.state.incomerData ? <div style={{position:'absolute', marginBottom:80, width:100, border:'4px solid #646464', borderRadius:6, backgroundColor:'#babab2'}}>
                                        <Typography align='center'>{this.state.incomerData ? parseFloat(this.state.incomerData.activeEnergy).toFixed(3) : null}</Typography>
                                    </div> : null}
                            </div>
                        </div>
                        <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center'}}>
                            <div style={{display:'flex', flex:1, flexDirection:'column' , gap:30}}>
                                <div style={{display:'flex',  alignItems:'center',}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #FFF200', borderRight:'6px dotted #FFF200'}}>
                                        <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                            background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                            width:100, height:100,
                                            borderRadius:130,
                                            boxShadow:'10px 8px 8px #00000029',
                                            border:'3px solid #E6E6E6'
                                        }} ><img src={LiveTrends}></img></Link>
                                    </div>
                                    <Typography variant='h5' style={{fontWeight:600, }}><span style={{color:'#00E927'}}> LIVE </span> TRENDS</Typography>
                                </div>
                                <div style={{display:'flex',  alignItems:'center',}}>
                                    <div style={{display:'flex', marginLeft:50, justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #00EBDF', borderRight:'6px dotted #00EBDF'}}>
                                        <Link component={Button} to="/dashboard/meterSettings" variant='contained' style={{
                                            background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                            width:100, height:100,
                                            borderRadius:130,
                                            boxShadow:'10px 8px 8px #00000029',
                                            border:'3px solid #E6E6E6'
                                        }} ><img src={MeterSettings}></img></Link>
                                    </div>
                                    <Typography variant='h5' style={{ fontWeight:600,}}><span style={{color:'#06A8F9'}}> METER </span> SETTINGS</Typography>
                                </div>
                                <div style={{display:'flex',  alignItems:'center',}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #008AED', borderRight:'6px dotted #008AED'}}>
                                        <Link component={Button} to='/dashboard/breakerControls' variant='contained' style={{
                                            background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                            width:100, height:100,
                                            borderRadius:130,
                                            boxShadow:'10px 8px 8px #00000029',
                                            border:'3px solid #E6E6E6'
                                        }} ><img src={BreakerControl}></img></Link>
                                    </div>
                                    <Typography variant='h5' display='inline' style={{fontWeight:600}}><span style={{color:'#0819F7'}}> BREAKER </span> CONTROLS</Typography>
                                </div>
                             </div>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', paddingBottom:10,  alignItems:'center',}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'auto', borderRadius:100, border:'6px solid #0525AA', borderRight:'6px dotted #0525AA'}}>
                            <Link component={Button} to='/dashboard/consumptionTrends' variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:100, height:100,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} ><img src={DataAna}></img></Link>
                        </div>
                        <Typography variant='h5' display='inline' style={{fontWeight:600}}><span style={{color:'#0819F7'}}> DATA</span> ANALYSE</Typography>
                    </div>
                </div>
            )
        } else {
            return(
                <Redirect to='/' />
            )
        }
        
    }
}

export default withRouter(Dashboard)