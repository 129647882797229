import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAOfx3nZ4wjvXzQPH5shtubsGDeJ-JQh5k",
    authDomain: "tesla-power-controls.firebaseapp.com",
    databaseURL: "https://tesla-power-controls-default-rtdb.firebaseio.com",
    projectId: "tesla-power-controls",
    storageBucket: "tesla-power-controls.appspot.com",
    messagingSenderId: "645988909943",
    appId: "1:645988909943:web:a44727579a98a62007d772",
    measurementId: "G-SFN1742Y8Q"
  };

const app = firebase.initializeApp(firebaseConfig);
export default !firebase.apps.length ? app : firebase.app();