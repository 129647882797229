import React from 'react';
import { Link } from 'react-router-dom';
import {Typography, Button, Card, Grid, FormControl, Select, InputLabel, MenuItem, TextField, InputBase } from '@material-ui/core';
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import LiveTrends from '../../svg/icons/liveTrends.svg';
import BreakerControl from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import app from '../../firebase/firebaseConfig';
import DataAna from '../../svg/DataAna.svg'

class MeterSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
            nFeeders : null,
            nDG : null,
            meters : null, 
            meterID: null,
            params : null,
            paramsRange : {},
            open : false,
            alertMsg : ""
        }
    }

    componentDidMount() {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                app.database().ref(`Users/${user.uid}`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        let data = snapShot.val()
                        let meterData = {}
                        snapShot.forEach((childSnapshot) => {
                            if (childSnapshot.key !== 'users') {
                                childSnapshot.forEach((mainSnapshot) => {
                                    meterData[mainSnapshot.key] = mainSnapshot.val()
                                })
                            }
                        })
                        let defaultMeter = Object.keys(meterData)[0]
                        this.setState({meters : meterData})
                        this.meterParams(defaultMeter)
                        this.setState({meterID : defaultMeter})
                        this.setState({nFeeders : Object.keys(data["feederMeters"]).length});
                        this.setState({nDG : Object.keys(data["dgMeters"]).length})
                    } 
                })
            }
        })
    }

    meterParams = (meterID) => {
        app.database().ref(`meterSettings/${meterID}`).once('value', (snapShot) => {
            if (snapShot.exists()) {
                app.database().ref(`meters/${meterID}`).once('value', (childSnapShot) => {
                    if (childSnapShot.exists()) {
                        let snapShotKeys = Object.keys(childSnapShot.val())
                        this.setState({params : snapShotKeys})
                        snapShot.forEach((childShot) => {
                            this.setState({paramsRange : {...this.state.paramsRange, [childShot.key] : childShot.val()}})
                        })
                    } else {
                        this.setState({params : null})
                    }
                })
                this.setState({paramsRange : snapShot.val()})
            } else {
                app.database().ref(`meters/${meterID}`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        let snapShotKeys = Object.keys(snapShot.val())
                        this.setState({params : snapShotKeys})
                    } else {
                        this.setState({params : null})
                    }
                })
            }
        })
    }

    render() {
        if (this.state.meterID) {
            let b_HW = 70;
            let bc_HW = 65;
            return (
                <div style={{display:'flex', flex:1, height:'94vh'}}>
                    <Snackbar open={this.state.open} anchorOrigin={{horizontal: 'right', vertical: 'top'}} autoHideDuration={6000} onClose={() => this.setState({open : false, alertMsg : ""})}>
                        <MuiAlert variant='filled' elevation={4} onClose={() => this.setState({open : false, alertMsg : ""})} severity="success">
                        {this.state.alertMsg}
                        </MuiAlert>
                    </Snackbar>
                    <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                        <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                            <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                                <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                                    <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>METER SETTINGS</span>
                                </Typography>
                            </div>
                            <div style={{display:'flex', flex:1, maxHeight:100, gap:10}}>
                                <div style={{display:'flex', flex:.2, flexDirection:'column', gap:10}}>
                                    <Card variant='elevation' elevation={4} style={{display:'flex', flex:1,  alignItems:'center', justifyContent:'space-between', padding:"0px 5px 0px 20px", borderRadius:14}}>
                                        <Typography style={{fontSize:12, display:'flex', flex:.8}}>NO OF FEEDERS</Typography>
                                        <Typography style={{color:'white', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10}}>{this.state.nFeeders ? this.state.nFeeders : "NAN"}</Typography>
                                    </Card>
                                    <Card variant='elevation' elevation={4} style={{display:'flex', flex:1, alignItems:'center', justifyContent:'space-between', padding:"0px 5px 0px 20px", borderRadius:14}}>
                                        <Typography style={{fontSize:12, display:'flex', flex:.8}}>NO OF DG'S</Typography>
                                        <Typography style={{color:'white', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10}}>{this.state.nDG ? this.state.nDG : "NAN"}</Typography>
                                    </Card>

                                </div>
                                <div style={{display:'flex', flex:1, justifyContent:'flex-end', padding:10}}>
                                    <Card variant='elevation' elevation={4} style={{minWidth:200, padding:'10px 30px', color:'#3F9DD4', borderRadius:20, backgroundColor:'#FCFCFC'}}>
                                        <FormControl style={{minWidth:200}}>
                                            <InputLabel id="demo-simple-select-label">Select Meter</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.meterID ? this.state.meterID : null}
                                            inputProps={{name : 'meter ID'}}
                                            onChange={(e) => {this.setState({meterID : e.target.value}, this.setState({paramsRange : {}}), this.meterParams(e.target.value))}}
                                            >
                                                {(Object.keys(this.state.meters)).map((key, index) => 
                                                    <MenuItem key={key} value={(Object.keys(this.state.meters))[index]}>{this.state.meters[Object.keys(this.state.meters)[index]]}</MenuItem>
                                                )}
                                                
                                            </Select>
                                        </FormControl>
                                    </Card>
                                </div>
                            </div>
                            <div style={{display:'flex', marginTop:30}}>
                                {this.state.params ? <div style={{display:'flex', flexDirection:'column', gap:15}}>
                                    <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', marginRight:20}}>
                                    {(this.state.params).map((key, index) => 
                                        <Card variant='elevation' elevation={4} key={key} style={{display:'flex', marginBottom:20, minWidth:"20%", maxWidth:"28%", padding:5, borderRadius:14, alignItems:'center', gap:10}}>
                                            <Typography style={{fontSize:12, display:'flex', flex:.6}}>{this.state.params[index]}</Typography>
                                            <InputBase onChange={(e) => {this.setState({paramsRange : {...this.state.paramsRange, [this.state.params[index]] : {...this.state.paramsRange[this.state.params[index]], from : e.target.value}}})}} inputProps={{ style: {textAlign: 'center'} }} value={this.state.paramsRange[this.state.params[index]] ? this.state.paramsRange[this.state.params[index]]["from"] : "X"} style={{color:'white', textAlign:'center', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10, minWidth:40}} />
                                            <InputBase onChange={(e) => {this.setState({paramsRange : {...this.state.paramsRange, [this.state.params[index]] : {...this.state.paramsRange[this.state.params[index]], to : e.target.value}}})}} inputProps={{ style: {textAlign: 'center'} }} value={this.state.paramsRange[this.state.params[index]] ? this.state.paramsRange[this.state.params[index]]["to"] : "X"} style={{color:'white', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10, minWidth:40}} />
                                        </Card>
                                    )}
                                        <Card variant='elevation' elevation={4} style={{display:'flex', marginBottom:20, minWidth:"20%", maxWidth:"28%", padding:5, borderRadius:14, alignItems:'center', gap:10, justifyContent:'space-between'}}>
                                            <Typography style={{fontSize:12, display:'flex', flex:.6}}>Available Maximum Demand</Typography>
                                            <InputBase onChange={(e) => {this.setState({paramsRange : {...this.state.paramsRange, "availableMaxDemand" : e.target.value}})}} inputProps={{ style: {textAlign: 'center'} }} value={this.state.paramsRange["availableMaxDemand"] ? this.state.paramsRange["availableMaxDemand"] : "X"} style={{color:'white', textAlign:'center', background:'#00ACF5', display:'flex', flex:.2, justifyContent:'center', padding:5, borderRadius:10, minWidth:40}} />
                                        </Card>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'flex-end', marginRight:20}}>
                                        <Card variant='elevation' elevation={4} component={Button} onClick={() => {app.database().ref(`meterSettings/${this.state.meterID}`).set(this.state.paramsRange).then(() => {this.setState({open : true, alertMsg : "Saved Successfully"})}).catch(() => {this.setState({open : true, alertMsg : "Failed!!"})})}}>SAVE</Card>
                                    </div>
                                     </div> : "No parameters"}
                            </div>
                            
                            
                        </div>
                    </div>

                    {/* Side Menu */}
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

export default MeterSettings