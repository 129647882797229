import React from 'react';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
  Typography,
  useTheme
} from '@material-ui/core';

const LineChart = (props) => {
  const theme = useTheme();
  const chart = {
    options: {
    //   noData: {
    //     text: "There's no data",
    //     align: 'center',
    //     verticalAlign: 'middle',
    //     offsetX: 0,
    //     offsetY: 0
    //     },
      chart: {
        // background: theme.palette.background.paper,
        background : 'rgba(40, 44, 52, 0)',
        stacked: false,
        toolbar : {
            show : false
        }
      },
      colors: ['#707070', '#00e396', '#FE9305'],
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: "#707070",
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        labels: {
          colors: "#e6e5e8"
        }
      },
      markers: {
        size: 3,
        colors:['#3CC18E'],
        // background: ,
        fillOpacity:1,
        strokeColors: ['#8a85ff', '#27c6db'],
        strokeWidth: 0,
        shape: 'circle',
        radius: 1,
        hover: {
          size: undefined,
          sizeOffset: 2
        }
      },
      stroke: {
        width: 1,
        curve: 'smooth',
        // lineCap: 'butt',
        dashArray: [0, 0]
      },
      theme: {
        mode: theme.palette.type
      },
      tooltip: {
        theme: theme.palette.type
      },
      xaxis: {
        axisBorder: {
          color: "#707070"
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: "#707070"
        },
        categories: props.timeSeries,
        // tickAmount: 10,
        labels: {
          style: {
            colors: "#707070"
          }
        }
      },
      yaxis: [
        {
          tickAmount:4,
          axisBorder: {
            show: false,
            color: "#e6e5e8"
          },
          axisTicks: {
            show: false,
            color: "#e6e5e8"
          },
          labels: {
            style: {
                fontSize: '8px',
                colors: "#707070"
            }
          }
        }
      ]
    },
    series: [{
        name: 'Yesterday',
        data: props.yesterdaySeries
      }, {
        name: 'Today',
        data: props.todaySeries
      }],
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 10
      }
    },
    
  };

  return (
    
        <Chart
          type="area"
          {...chart}
        />
  );
};

export default LineChart;
