import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, FormControl, Select, InputLabel, MenuItem, Typography } from '@material-ui/core';
import logo from '../../svg/icons/logo.svg';
import home from '../../svg/icons/home.svg';
import LiveTrends from '../../svg/icons/liveTrends.svg';
import BreakerControl from '../../svg/icons/breakerControl.svg';
import FeederMonitor from '../../svg/icons/feederMonitor.svg';
import BarChart from '../widgets/BarChart';
import ConLineChart from '../widgets/ConLineChart';
import app from '../../firebase/firebaseConfig';
import MonLineChart from '../widgets/monLineChart';
import { readString } from 'react-papaparse';
import DataAna from '../../svg/DataAna.svg';

const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
class ConsumptionTrends extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentYear : this.getYear(),
            currentMonth : this.getMonth(),
            currentYearValues : new Array(12).fill(0),
            lastYearValues : new Array(12).fill(0),
            currentMonthValues : new Array(this.getDaysInMonth(this.getMonth(), this.getYear())).fill(0),
            lastMonthValues : new Array(this.getDaysInMonth(this.getMonth()-1, this.getYear())).fill(0),
            todayTimeSeries : [],
            yesterdayTimeSeries : [],
            todayActiveEnergySeries : [],
            yesterdayActiveEnergySeries : [],
            meters : {},
            meterID : localStorage.getItem('i_id'),
            todayConsumption : 0,
            monthlyConsumption : 0
        }
    }

    componentDidMount = () => {
        this.getMeters()
        this.pageProcess(this.state.meterID)
       
    }

    pageProcess = (meterId) => {
        let mID = meterId
        app.firestore().collection('Meters').doc(mID).get().then((doc) => {
            if (doc.exists) {
                let data = doc.data()['consumption']
                if (data) {
                    let currentYear = this.state.currentYear
                    let lastYear = currentYear - 1
                    let currentMonth = this.state.currentMonth + 1
                    let currentYearValues = new Array(12).fill(0)
                    let lastYearValues = new Array(12).fill(0)
                    if (data[lastYear]) {
                        let keys = Object.keys(data[lastYear])
                        for (let i = 0 ; i < keys.length; i++) {
                            lastYearValues[keys[i] -1] = data[lastYear][keys[i]]['kwh']
                        }
                        this.setState({lastYearValues : lastYearValues})
                    }
                    if (data[currentYear]) {
                        let keys = Object.keys(data[currentYear])
                        for (let i = 0 ; i < keys.length; i++) {
                            currentYearValues[keys[i] -1] = data[currentYear][keys[i]]['kwh']
                        }
                        // currentYearValues[currentMonth] = 
                        this.setState({currentYearValues : currentYearValues})
                    }
                    
                    let currentYearData = data[currentYear]
                    let previousYearData = data[currentYear - 1]

                    //  Last Month Data
                    if (currentYearData && currentYearData[currentMonth]) {
                        let lastMonthValues = []
                        let data = []
                        if (this.getMonth()-1 === -1) {
                            lastMonthValues = new Array(this.getDaysInMonth(31, this.getYear()-1)).fill(0)
                            data = previousYearData[12]
                        } else {
                            lastMonthValues = new Array(this.getDaysInMonth(this.getMonth()-1, this.getYear())).fill(0)
                            data = currentYearData[currentMonth-1]
                        }
                        delete data.kwh
                        let keys = Object.keys(data)
                        for (let i = 0 ; i < keys.length; i++) {
                            if (i !==0){
                                lastMonthValues[keys[i] -1] = (data[keys[i]] - data[keys[i-1]]).toFixed(3)
                            }else {
                                let lastDayLastMonth = 0
                                let lastMonth = currentMonth -1
                                if (lastMonth === 1 && keys[i] === 1) {
                                    lastDayLastMonth = this.getDaysInMonth(11, currentYear-1)
                                } else {
                                    lastDayLastMonth = this.getDaysInMonth(lastMonth-2, currentYear)
                                }
                                lastMonthValues[keys[i] -1] = data[keys[i]]
                            }
                        }
                        this.setState({lastMonthValues : lastMonthValues})
                    }
                    // Current Month Data
                    if (currentYearData && currentYearData[currentMonth]) {
                        let currentMonthValues =  new Array(this.getDaysInMonth(this.getMonth(), this.getYear())).fill(0)
                        let data = currentYearData[currentMonth]        // current month data
                        delete data.kwh
                        let keys = Object.keys(data)
                        for (let i = 0 ; i < keys.length; i++) {
                            if (i !== 0){
                                currentMonthValues[keys[i] -1] = (data[keys[i]] - data[keys[i-1]]).toFixed(3)
                            }else {
                                let lastDayLastMonth = 0
                                if (currentMonth === 1 && parseInt(keys[i]) === 1) {
                                    lastDayLastMonth = this.getDaysInMonth(11, currentYear-1)
                                    currentMonthValues[keys[i] -1] = (data[keys[i]] - previousYearData[12][31]).toFixed(3)
                                } else {
                                    lastDayLastMonth = this.getDaysInMonth(currentMonth-2, currentYear)
                                    currentMonthValues[keys[i] -1] = (data[keys[i]] - currentYearData[currentMonth-1][lastDayLastMonth]).toFixed(3)
                                    
                                }
                                
                            }
                        }
                        this.setState({currentMonthValues : currentMonthValues})
                    }
                    // For daily 60 min check
                    let todayDate = new Date()
                    let yesterdayDate = new Date(todayDate)
                    yesterdayDate.setDate(yesterdayDate.getDate() - 1)
                    let todayFilename = this.getFileName(todayDate)
                    let yesterdayFileName = this.getFileName(yesterdayDate)
                    this.handleChartData(todayFilename, mID, true)
                    this.handleChartData(yesterdayFileName, mID)
                    let l_Consumption = 0;
                    let t_Consumption = 0;
                    // Data for this month's consumption and todays consumption
                    app.database().ref(`meters/${mID}/activeEnergy`).on("value", (firebaseSnapshot) => {
                        if (firebaseSnapshot.exists) {
                            let y_Consumption = 0
                            if (todayDate.getDate() === 1) {
                                y_Consumption = data[currentYear][currentMonth-1][yesterdayDate.getDate()] ? data[currentYear][currentMonth-1][yesterdayDate.getDate()] : 0
                            } else {
                                y_Consumption = data[currentYear] && data[currentYear][currentMonth][yesterdayDate.getDate()] ? data[currentYear][currentMonth][yesterdayDate.getDate()] : 0
                            }
                            
                            l_Consumption = currentYearValues[currentMonth-2] ? (currentYearValues[currentMonth-2]) : 0 
                            t_Consumption = firebaseSnapshot.val()
                            // console.log(t_Consumption - y_Consumption)
                            this.setState({todayConsumption : (t_Consumption - y_Consumption).toFixed(4), monthlyConsumption : (t_Consumption - l_Consumption).toFixed(4)})
                        }
                    })

                    
                }   
            }
        })
    }
    getMeters = () => {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                app.database().ref(`Users/${user.uid}`).once('value', (snapShot) => {
                    if (snapShot.exists()) {
                        let data = {}
                        snapShot.forEach((childSnapshot) => {
                            if (childSnapshot.key !== 'users') {
                                childSnapshot.forEach((mainSnapshot) => {
                                    data[mainSnapshot.key] = mainSnapshot.val()
                                })
                            }
                        })
                        
                        this.setState({meters : data})
                    }
                })
            }
        })
    }

    getYear = () => {
        let d = new Date()
        return d.getFullYear()
    }

    getMonth = () => {
        let d = new Date()
        return d.getMonth()
    }

    getDaysInMonth = (month, year) => {
        return new Date(year, month+1, 0).getDate()
    }

    getFileName = (date) => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        return date =yyyy + mm + dd;
    }

    getMinutes = (date) => {
        return date.getMinutes() 
    }

    getHour = (date) => {
        return date.getHours()
    }
    timestampTodate = (timestamp) => {
        return new Date(timestamp)
    }

    handleChartData = (filename, meterID, today) => {
        app.storage().ref(`${meterID}/${filename}.csv`).getDownloadURL().then((snapshot) => {
            var xhr = new XMLHttpRequest();
            let url = `${snapshot}`;
            xhr.responseType = 'text'; 
            xhr.onload = () => {
                var csv= xhr.response;
                var result = readString(csv)
                let headings = result.data[0];
                let timestampIndex = 21;
                let activeEnergyIndex = 0;
                for (let i =0; i< headings.length; i++) {
                    if (headings[i] === 'timestamp') {
                        timestampIndex = i
                    } else if (headings[i] === 'activeEnergy') {
                        activeEnergyIndex = i
                    }
                }
                let data = result.data
                data.shift()
                let CurrentMinute = this.getMinutes(new Date())
                let CurrentHour = this.getHour(new Date())
                let param = {}
                param.endMin = CurrentMinute;
                param.endHour = CurrentHour;
                if (CurrentMinute - 61 < 0) {
                    param.startHour = CurrentHour -1;
                    param.startMin = 60 + (CurrentMinute - 61)
                }else {
                    param.startHour = CurrentHour;
                    param.startMin = (CurrentMinute - 61)
                }
                let timeSeries = []
                let activeEnergy = []
                for (let i = 0; i < data.length; i++) {
                    let childData = data[i]
                    let time = childData[timestampIndex]
                    let timestampHour = this.getHour(new Date(parseInt(time)))
                    let timestampMin = this.getMinutes(new Date(parseInt(time)))
                    if (param.startHour === param.endHour) {
                        if (param.startHour === timestampHour) {
                            if (timestampMin > param.startMin && timestampMin <= param.endMin) {
                                timeSeries.push(`${timestampHour}:${timestampMin}`)
                                activeEnergy.push(childData[activeEnergyIndex])
                            }
                        } 
                    }else {
                            if (param.startHour === timestampHour) {
                                if (timestampMin > param.startMin && timestampMin <= 60) {
                                    timeSeries.push(`${timestampHour}:${timestampMin}`)
                                    activeEnergy.push(childData[activeEnergyIndex])
                                }
                            }else if (timestampHour === param.endHour) {
                                if (timestampMin > 0 && timestampMin <= param.endMin) {
                                    timeSeries.push(`${timestampHour}:${timestampMin}`)
                                    activeEnergy.push(childData[activeEnergyIndex])
                                }
                            }
                        }
                    }
                if (today) {
                    this.setState({todayTimeSeries : timeSeries, todayActiveEnergySeries : activeEnergy})
                } else {
                    this.setState({yesterdayTimeSeries : timeSeries, yesterdayActiveEnergySeries : activeEnergy})
                }
                
                // console.log(headings, timestampIndex, reactiveEnergyIndex)            
            };
            xhr.open('GET', url);
            xhr.send();
            
        }).catch((error) => {
            // Handle any errors
            // let [time, chart1Data, chart2Data, chart3Data] = [[], [], [], []];
            // this.setState({time: time, chart1Data: chart1Data, chart2Data: chart2Data, chart3Data: chart3Data})
          });
                    
    }
    
    handleMeterChange = async (e) => {
        this.setState({meterID : e.target.value})
        await this.setState({currentYear : this.getYear(),
            currentMonth : this.getMonth(),
            currentYearValues : new Array(12).fill(0),
            lastYearValues : new Array(12).fill(0),
            currentMonthValues : new Array(this.getDaysInMonth(this.getMonth(), this.getYear())).fill(0),
            lastMonthValues : new Array(this.getDaysInMonth(this.getMonth()-1, this.getYear())).fill(0),
            todayTimeSeries : [],
            yesterdayTimeSeries : [],
            todayActiveEnergySeries : [],
            yesterdayActiveEnergySeries : [],})
        
        this.pageProcess(e.target.value)
        
    }

    render() {
        let b_HW = 70;
        let bc_HW = 65;
        return (
            <div style={{display:'flex', flex:1, height:'90vh'}}>
                <div style={{display:'flex', flex:1, flexDirection:'column', padding:10}}>
                    <div style={{display:'flex', flex:1, justifyContent:'center', flexDirection:'column', maxHeight:70}}>
                        <Typography variant='caption' align='center' display='inline' gutterBottom={false}>
                            <span style={{ fontSize:18, backgroundColor:'#00ACF5', padding:'0px 40px 0px 40px'}}>DATA ANALYSE</span></Typography>
                    </div>
                    <div style={{display:'flex', minHeight:100, gap:20, fontSize:20, marginBottom:20, justifyContent:'center'}}>
                        <Card style={{display:'flex', width:250, flexDirection:'column', padding:2}}>
                            <div style={{display:'flex', flex:.8, justifyContent:'center', alignItems:'center'}}>
                                <Card variant="outlined" style={{background:'linear-gradient(#00e396, #00e3969c)', opacity:.4, width:150,
                                    display:'flex', justifyContent:'center',alignItems:'center', height:50, outlineColor:'#00e396'}}>
                                    {this.state.todayConsumption}
                                </Card>
                            </div>
                            <div style={{flex:.2, display:'flex', backgroundColor:'#1c6d9c', color:'white', borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                                <Typography style={{textAlign:'center'}}>Today's Consumption</Typography>
                            </div>
                        </Card>
                        <Card style={{display:'flex', width:250, flexDirection:'column', padding:2}}>
                            <div style={{display:'flex', flex:.8, justifyContent:'center', alignItems:'center'}}>
                                <Card variant="outlined" style={{background:'linear-gradient(#00e396, #00e3969c)', opacity:.4, width:150,
                                    display:'flex', justifyContent:'center',alignItems:'center', height:50, outlineColor:'#00e396'}}>
                                    {this.state.monthlyConsumption}
                                </Card>
                            </div>
                            <div style={{flex:.2, display:'flex', backgroundColor:'#1c6d9c', color:'white', borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                                <Typography style={{textAlign:'center'}}>This Month's Consumption</Typography>
                            </div>
                        </Card>
                        <Card style={{padding:'10px 30px', color:'#3F9DD4',  width:200, backgroundColor:'#FCFCFC', right:10, justifyContent:'center', alignItems:'center', display:'flex'}}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Select Meter</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.meterID}
                                onChange={this.handleMeterChange}
                                style={{flex:1, width:170}}
                                >
                                    {(Object.keys(this.state.meters)).map((key, index) => 
                                        <MenuItem key={key} value={(Object.keys(this.state.meters))[index]}>{this.state.meters[Object.keys(this.state.meters)[index]]}</MenuItem>
                                    )}
                                    
                                </Select>
                            </FormControl>
                        </Card>
                    </div>
                    <div style={{display:'flex', minHeight:300, gap:20, fontSize:25}}>
                        <Card style={{display:'flex', flexDirection:'column', flex:1, padding:2}}>
                            <div style={{flex:.8}}>
                                <BarChart currentYear={this.state.currentYear} currentYearData={this.state.currentYearValues} lastYearData = {this.state.lastYearValues} />
                            </div>
                            <div style={{flex:.2, display:'flex', backgroundColor:'#1c6d9c', color:'white', borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                                <Typography style={{textAlign:'center'}}>Monthly Consumption <br></br> Last Year & Current Year</Typography>
                            </div>
                        </Card>
                        <Card style={{display:'flex', flexDirection:'column', flex:1, padding:2}}>
                            <div style={{flex:.8}}>
                                <MonLineChart currentMonth={monthName[this.state.currentMonth]} lastMonth={monthName[this.state.currentMonth-1 === -1 ? 11 : this.state.currentMonth-1]}
                                    lastMonthValues={this.state.lastMonthValues} currentMonthValues={this.state.currentMonthValues}
                                 />
                            </div>
                            <div style={{flex:.2, backgroundColor:'#1c6d9c', display:'flex', color:'white', borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                                <Typography style={{textAlign:'center'}}>Monthly / Daily <br></br> Consumption Analysis</Typography>
                            </div>
                        </Card>
                        <Card style={{display:'flex', flexDirection:'column', flex:1, padding:2}}>
                            <div style={{flex:.8}}>
                                <ConLineChart timeSeries={this.state.todayTimeSeries.length === 0 ? this.state.yesterdayTimeSeries : this.state.todayTimeSeries} todaySeries={this.state.todayActiveEnergySeries} yesterdaySeries={this.state.yesterdayActiveEnergySeries} />
                            </div>
                            <div style={{flex:.2, backgroundColor:'#1c6d9c', display:'flex', color:'white', borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                                <Typography style={{textAlign:'center'}}>60-Min Consumption<br></br> Yesterday & Today</Typography>
                            </div>
                        </Card>
                        
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:10, minWidth:150, borderLeft:"4px solid", borderImage:'linear-gradient(to left, #B0F045 0%, #00EBDF 100%) 1 100%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #0B0094', borderTop:'4px dotted #0B0094'}}>
                            <Link component={Button} to="/" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={logo} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={home} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px dashed #FF00BF', borderTop:'4px dotted #FF00BF'}}>
                            <Link component={Button} to="/dashboard/consumptionTrends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={DataAna} width={40} height={40}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderRight:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/trends" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={LiveTrends} width={30}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #FFF200', borderLeft:'4px dotted #FFF200'}}>
                            <Link component={Button} to="/dashboard/feederMonitor" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={FeederMonitor} width={20}></img>
                            </Link>
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:b_HW, height:b_HW, borderRadius:100, border:'4px solid #AA00FF', borderRight:'4px dotted #AA00FF'}}>
                            <Link component={Button} to="/dashboard/breakerControls" variant='contained' style={{
                                background:'transparent linear-gradient(317deg, #FFFFFF 0%, #E6E5E9 100%) 0% 0% no-repeat padding-box', 
                                width:bc_HW, height:bc_HW,
                                borderRadius:130,
                                boxShadow:'10px 8px 8px #00000029',
                                border:'3px solid #E6E6E6'
                            }} >
                                <img src={BreakerControl} width={30}></img>
                            </Link>
                        </div>
                    </div>
            </div>
        )
    }
}

export default ConsumptionTrends;