import React from 'react'

class AboutUs extends React.Component {
    constructor(props) {
        super(props)

    }

    componentDidMount() {

    }

    render() {
        return(
            <div>
                AboutUs
            </div>
        )
    }
}

export default AboutUs